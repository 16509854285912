import { getSingleProfile, getUsersStateless } from "deso-protocol";
import { deso_graphql } from "../utils/graphql";
import { EventEmitter } from "eventemitter3";

const profileCache = {};
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const profileEmitter = new EventEmitter();
export { profileEmitter };

const batchQueue = new Set();
const BATCH_DELAY = 50; // 500ms delay for batch processing
const MAX_BATCH_SIZE = 100; // Max number of profiles per batch
let batchTimeout = null;

// Helper function to schedule a public key for batch fetching
const scheduleBatch = (publicKey) => {
  batchQueue.add(publicKey);
  if (!batchTimeout) {
    batchTimeout = setTimeout(() => {
      fetchProfilesBatch();
      batchTimeout = null;
    }, BATCH_DELAY);
  }
};

// Function to fetch profiles in a batch
const fetchProfilesBatch = async () => {
  if (batchQueue.size === 0) return;

  // Limit the batch size to MAX_BATCH_SIZE
  const publicKeys = Array.from(batchQueue).slice(0, MAX_BATCH_SIZE);
  batchQueue.clear();

  try {
    const response = await getUsersStateless({
      PublicKeysBase58Check: publicKeys,
      NoErrorOnMissing: true,
      SkipForLeaderBoard: true,
    });

    console.log("[profileCache.js] fetchProfilesBatch return:", response);

    if (response?.UserList) {
      response.UserList.forEach((user) => {
        const publicKey = user.PublicKeyBase58Check;
        const profile = user.ProfileEntryResponse;

        if (publicKey && profile) {
          const profileCopy = {
            ...profile,
            lastChecked: new Date().getTime(),
          };
          profileCache[publicKey] = profileCopy;
          profileEmitter.emit("profileUpdated", profileCopy);
        } else if (publicKey) {
          profileCache[publicKey] = { PublicKeyBase58Check: publicKey };
        }
      });
    }
  } catch (error) {
    console.error("Error fetching profiles in batch:", error);
  }
};

// Fetch profile from cache or schedule for batch fetching
export const getProfileFromCache = async (publicKey, profile = null) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && now - cachedProfile.lastChecked < REFRESH_INTERVAL) {
    // Use cached profile if it's still valid
    return cachedProfile;
  } else if (profile) {
    // If profile is provided, add it to the cache and return it
    const enrichedProfile = {
      ...profile,
      lastChecked: now,
    };
    profileCache[publicKey] = enrichedProfile;
    return enrichedProfile;
  } else {
    // Schedule the profile for batch fetching
    scheduleBatch(publicKey);

    // Return a placeholder or null until the profile is fetched
    return { PublicKeyBase58Check: publicKey };
  }
};

// Add multiple profiles to cache
export const addProfilesToCache = (profiles) => {
  Object.keys(profiles).forEach((publicKey) => {
    profileCache[publicKey] = profiles[publicKey];
  });
};

// Add a single profile to cache
export const addProfileToCache = (publicKey, profile) => {
  profileCache[publicKey] = profile;
};

// Listen for profile updates
export const onProfileUpdated = (listener) => {
  profileEmitter.on("profileUpdated", listener);
  return () => profileEmitter.off("profileUpdated", listener);
};

export const getSingleProfileFromCache = async (identifier) => {
  const cachedProfile = profileCache[identifier];
  const now = new Date().getTime();

  // Check if profile is in the cache and if it is still valid
  if (cachedProfile && now - cachedProfile.lastChecked < REFRESH_INTERVAL) {
    return cachedProfile;  // Return the cached profile if it's still valid
  }

  // Determine if the identifier is a public key or username
  const isPublicKey = identifier && identifier.startsWith("BC1YL");

  let profile = null;
  try {
    if (isPublicKey) {
      // Fetch profile by public key
      const request = { PublicKeyBase58Check: identifier, NoErrorOnMissing: true };
      const data = await getSingleProfile(request);
      profile = data.Profile;
    } else {
      // Fetch profile by username
      const request = { Username: identifier, NoErrorOnMissing: true };
      const data = await getSingleProfile(request);
      profile = data.Profile;
    }

    if (profile) {
      // Add the profile to the cache with the current time
      const profileCopy = { ...profile, lastChecked: now };
      profileCache[identifier] = profileCopy;
      profileEmitter.emit("profileUpdated", profileCopy);  // Emit the profile update event
      return profileCopy;  // Return the fetched profile
    }
  } catch (error) {
    console.error("Error fetching profile:", error);
  }

  return null;  // Return null if there was an error fetching the profile
};

// Fetch latest transaction by public key (retained for other use cases)
export async function fetchLatestTransactionByPublicKey(publicKey) {
  try {
    const request = {
      query: `
        query latestTransaction($first: Int, $filter: TransactionFilter, $orderBy: [TransactionsOrderBy!]) {
          transactions(first: $first, filter: $filter, orderBy: $orderBy) {
            nodes {
              timestamp
            }
          }
        }`,
      variables: {
        first: 1,
        filter: {
          publicKey: {
            equalTo: publicKey,
          },
        },
        orderBy: "TIMESTAMP_DESC",
      },
      operationName: "latestTransaction",
    };
    const response = await deso_graphql(request);
    return response?.data?.transactions?.nodes[0]?.timestamp || null;
  } catch (error) {
    console.error("Error fetching latest transaction:", error);
    return null;
  }
}


/* Original below, updated above to batch process
import { getSingleProfile } from "deso-protocol";
import { deso_graphql } from "../utils/graphql";
import { EventEmitter } from 'eventemitter3';
const profileCache = {};
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const MAX_RETRIES = 3; // Maximum number of retries for failed requests
const profileEmitter = new EventEmitter();
export { profileEmitter };


export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    //console.log("[ProfileCache] Current Cache:",profileCache);
    console.log("[ProfileCache] returning profile:",cachedProfile);
    return cachedProfile;
  } else {
    const fetchedProfile = await fetchProfileWithRetry(publicKey, profile);
    if (fetchedProfile) {
      profileCache[fetchedProfile.PublicKeyBase58Check] = fetchedProfile;
    }
    return fetchedProfile;
  }
};

const fetchProfileWithRetry = async (publicKey, suppliedProfile, retries = 0) => {
  try {
    let profile = suppliedProfile;

    if (!profile) {
      if (publicKey && publicKey.startsWith('BC1YL')) {
        // Profile is not supplied, fetch it using public key
        const request = {
          PublicKeyBase58Check: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      } else {
        // Profile is not supplied, fetch it using username
        const request = {
          Username: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      }
    }

    if (profile && profile.PublicKeyBase58Check) {
      // Immediately return the profile without waiting for the latest transaction
      const profileCopy = { ...profile, lastChecked: new Date().getTime() };
      // Fetch latest transaction asynchronously
      //fetchLatestTransactionAndUpdateProfile(profileCopy);
      return profileCopy;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      //console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, suppliedProfile, retries + 1); // Pass suppliedProfile on retry
    } else {
      //console.log('No profile for anonymous key:', publicKey, error);
      return { PublicKeyBase58Check: publicKey };
    }
  }
};

// Function to fetch the latest transaction and update the profile asynchronously
const fetchLatestTransactionAndUpdateProfile = async (profile) => {
  try {
    const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
    profile.lastTransaction = latestTransaction;
    //console.log('profileUpdated', profile);
    profileCache[profile.PublicKeyBase58Check] = profile;

    // Emit an event to notify that the profile has been updated
    profileEmitter.emit('profileUpdated', profile);
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
  }
};

export const addProfilesToCache = (profiles) => {
  Object.keys(profiles).forEach(publicKey => {
    profileCache[publicKey] = profiles[publicKey];
  });
};

export const addProfileToCache = (publicKey, profile) => {
  profileCache[publicKey] = profile;
};

export const onProfileUpdated = (listener) => {
  profileEmitter.on('profileUpdated', listener);
};

export async function fetchLatestTransactionByPublicKey(publicKey) {
  try {
    const request = {
      query: `
        query latestTransaction($first: Int, $filter: TransactionFilter, $orderBy: [TransactionsOrderBy!]) {
          transactions(first: $first, filter: $filter, orderBy: $orderBy) {
            nodes {
              timestamp
            }
          }
        }`,
      variables: {
        first: 1,
        filter: {
          publicKey: {
            equalTo: publicKey,
          }
        },
        orderBy: "TIMESTAMP_DESC"
      },
      operationName: "latestTransaction"
    };
    const response = await deso_graphql(request);
    return response?.data?.transactions?.nodes[0]?.timestamp || null;
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
    return null;
  }
}

/*
export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    return cachedProfile;
  } else {
    const profile = await fetchProfileWithRetry(publicKey, profile);
    if (profile) {
      profileCache[profile.PublicKeyBase58Check] = profile;
    }
    return profile;
  }
};
const fetchProfileWithRetry = async (publicKey, profile, retries = 0) => {
  try {
    let profile = null;
    if (publicKey && publicKey.startsWith('BC1YL')) {
      //profile = await getProfileAPI(publicKey, null);
      const request = {
        PublicKeyBase58Check: publicKey,
        //Username: null,
        NoErrorOnMissing: true
      }
      const data = await getSingleProfile(request);
      profile = data.Profile;
    } else {
      //profile = await getProfileAPI(null, publicKey);
      const request = {
        //PublicKeyBase58Check: null,
        Username: publicKey,
        NoErrorOnMissing: true
      }
      const data = await getSingleProfile(request);
      profile = data.Profile;
    }
    
    if (profile && profile.PublicKeyBase58Check) {
      const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
      profile.lastTransaction = latestTransaction;
      profile.lastChecked = new Date().getTime(); // Store as timestamp
      return profile;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, retries + 1);
    } else {
      console.log('No profile for anonymous key:', publicKey, error);
      return null;
    }
  } finally {
    //console.log("ProfileCache:", profileCache);
  }
};

const fetchProfileWithRetry = async (publicKey, suppliedProfile, retries = 0) => {
  try {
    let profile = suppliedProfile;

    if (!profile) {
      if (publicKey && publicKey.startsWith('BC1YL')) {
        // Profile is not supplied, fetch it using public key
        const request = {
          PublicKeyBase58Check: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      } else {
        // Profile is not supplied, fetch it using username
        const request = {
          Username: publicKey,
          NoErrorOnMissing: true
        };
        const data = await getSingleProfile(request);
        profile = data.Profile;
      }
    }

    if (profile && profile.PublicKeyBase58Check) {
      const latestTransaction = await fetchLatestTransactionByPublicKey(profile.PublicKeyBase58Check);
      profile.lastTransaction = latestTransaction;
      profile.lastChecked = new Date().getTime(); // Store as timestamp
      return profile;
    } else {
      throw new Error("Profile data is invalid");
    }
  } catch (error) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch profile ${publicKey} (${retries + 1}/${MAX_RETRIES})...`);
      return fetchProfileWithRetry(publicKey, suppliedProfile, retries + 1); // Pass suppliedProfile on retry
    } else {
      console.log('No profile for anonymous key:', publicKey, error);
      return null;
    }
  }
};

export const getProfileFromCache = async (publicKey, profile) => {
  const cachedProfile = profileCache[publicKey];
  const now = new Date().getTime();

  if (cachedProfile && (now - cachedProfile.lastChecked < REFRESH_INTERVAL)) {
    return cachedProfile;
  } else {
    const fetchedProfile = await fetchProfileWithRetry(publicKey, profile);
    if (fetchedProfile) {
      profileCache[fetchedProfile.PublicKeyBase58Check] = fetchedProfile;
    }
    return fetchedProfile;
  }
};


export const addProfilesToCache = (profiles) => {
  Object.keys(profiles).forEach(publicKey => {
    profileCache[publicKey] = profiles[publicKey];
  });
};

export const addProfileToCache = (publicKey, profile) => {
  profileCache[publicKey] = profile;
};

export async function fetchLatestTransactionByPublicKey(publicKey) {
  try {
    const request = {
      query: `
        query latestTransaction($first: Int, $filter: TransactionFilter, $orderBy: [TransactionsOrderBy!]) {
          transactions(first: $first, filter: $filter, orderBy: $orderBy) {
            nodes {
              timestamp
            }
          }
        }`,
      variables: {
        first: 1,
        filter: {
          publicKey: {
            equalTo: publicKey,
          }
        },
        orderBy: "TIMESTAMP_DESC"
      },
      operationName: "latestTransaction"
    };
    const response = await deso_graphql(request);
    return response?.data?.transactions?.nodes[0]?.timestamp || null;
  } catch (error) {
    console.error('Error fetching latest transaction:', error);
    return null;
  }
}
  */
