import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ProfileCard, UserInput } from "../utils/profiles";
import { Loader, updateDocumentTitle } from "../utils/helpers";
import { getSingleProfileFromCache, onProfileUpdated } from "../services/profileCache";
import { DeSoIdentityContext } from "react-deso-protocol";
import { AccessGroupsContext } from "../contexts/AccessGroups";
import ExchangeRatesContext from "../contexts/ExchangeRatesContext";
import { useUserPreferences } from "../contexts/UserPreferences";

export const User = () => {
  const { username, tab, blog, feed } = useParams();
  const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
  const { accessGroups } = useContext(AccessGroupsContext);
  const { preferences } = useUserPreferences();
  const { exchangeRates } = useContext(ExchangeRatesContext);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  //console.log("[user.jsx] username, tab, blog, feed, currentUser, preferences", username, tab, blog, feed, currentUser, preferences,exchangeRates);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Set loading true before fetching
        setLoading(true);
        
        const cachedProfile = await getSingleProfileFromCache(username);

        if (cachedProfile) {
          cachedProfile.lastChecked = new Date(Date.now()).toUTCString();
          setProfile(cachedProfile);
        }
      } catch (error) {
        console.error("[User] Error fetching profile:", error);
      } finally {
        setLoading(false); // Always set loading to false when done
      }
    };

    fetchProfile();

    // Listener function for profile updates
    const handleProfileUpdate = (updatedProfile) => {
      // Only update if the updated profile matches the current username
      if (updatedProfile?.Username === username) {
        setProfile((prev) => {
          // Only update if profile content has changed and is different
          if (!prev || JSON.stringify(prev) !== JSON.stringify(updatedProfile)) {
            return updatedProfile;
          }
          return prev; // No update if the profile is the same
        });
      }
    };

    // Register listener and clean up on unmount
    const cleanup = onProfileUpdated(handleProfileUpdate);

    return () => {
      cleanup(); // Properly remove listener on unmount
    };
  }, [username]);

  if (loading) {
    // Render loader if still loading
    return (
      <div className="container">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="my-5 py-5">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  const navigateTo = (selectedUsername) => {
    //console.log("[User.jsx] navigateTo: ",selectedUsername);
    navigate(`/u/${selectedUsername.value}`);
  };

  return (
    <>
      {profile && profile.PublicKeyBase58Check ? (
        <ProfileCard profile={profile} mode={`full`} currentUser={currentUser} alternateUsers={alternateUsers} accessGroups={accessGroups} preferences={preferences} exchangeRates={exchangeRates} tab={tab} feed={feed} />
      ) : (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <div className="my-5 py-5">
              <h1>Sorry, profile does not exist</h1>
              <p>'{username} does not exist.</p>
            </div>
            <div className="">
              <p>Try finding another profile:</p>
              <UserInput onSelect={navigateTo} />
            </div>
          </div>
      </div>
      )}
    </>
  );
};

