import { useEffect, useState } from "react";
import { deso_api } from "./graphql";
import { Link } from "react-router-dom";
import { ReplyForm } from "../components/modalReply";
import { PostPlaceholder, Reply } from "./layouts_posts";

export const PostComments = ({ post, currentUser, level, preferences, accessGroups, thread, showPost = false }) => {
  //console.log("[posts.js] <PostComments> thread, post:",thread,post);
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentOffset, setCommentOffset] = useState(0);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const [showComments, setShowComments] = useState(false); 
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [threadBreakOut, setThreadBreakout] = useState(thread ? true : false);
  //const [refreshPostComments, setRefreshPostComments] = useState(true);

  const toggleReplyForm = (event) => {
      setShowReplyForm(prevState => !prevState);
      if (event && event.currentTarget) {
          event.currentTarget.blur(); // Remove the :active state
      }
  };

  // if post changes reset and reload
  useEffect(() => {
      if(post.Comments && post.Comments.length > 0) { 
          if(thread) { 
              //console.log("[posts.js] -------- ",post.Comments,post.Comments.length);
              setComments(post.Comments);
              setShowComments(true); 
          }
      } else { 
          setComments([]);
      }
      setCommentOffset(0);
      setLoadMoreVisible(false);
      setThreadBreakout(false);
  }, [post]);

  const refreshPostComments = () => {
      setComments([]);
      loadComments();
  };

  const loadCommentsThread = () => {
      loadComments();
      setThreadBreakout(true);
  };

  const loadComments = async () => {
      try {
          setShowComments(true);
          setLoadingComments(true);
          //console.log("comments, current user:", currentUser);
          const request = {
              PostHashHex: post.PostHashHex,
              ReaderPublicKeyBase58Check: currentUser?.PublicKeyBase58Check,
              CommentOffset: commentOffset,
              CommentLimit: 50
          };
          // Make API request to load comments
          const response = await deso_api('get-single-post', request); // Assuming deso_api is a function to make API requests
          const newComments = response?.PostFound?.Comments || [];
  
          // Filter out comments that are already present in the comments array
          const uniqueNewComments = newComments.filter(newComment => !comments.some(existingComment => existingComment.PostHashHex === newComment.PostHashHex));
  
          setComments(prevComments => [...prevComments, ...uniqueNewComments]);
          if (uniqueNewComments.length < 50) {
              setLoadMoreVisible(false);
          }
          setCommentOffset(prevOffset => prevOffset + uniqueNewComments.length);
      } catch (error) {
          //console.error('Error loading comments:', error);
      } finally {
          setLoadingComments(false);
      }
  };
  

  const handleLoadMore = () => {
      loadComments();
  };

  const toggleCommentsVisibility = () => {
      if(showComments && thread) { setThreadBreakout(true); }
      setShowComments(prevShowComments => !prevShowComments);
  };

  //console.log("[posts.js] <PostComments> comments:", comments);
  return (
      <>
          <div className={`replyPanel rounded-0 d-flex flex-row flex-nowrap justify-content-between border-0 small ps-4 py-2`} style={{ zIndex: "100" }}>
              <div className='flex-fill text-start'>
                  {!threadBreakOut && comments.length > 0 && countNestedPosts(post) < post.CommentCount ? (
                      <span className="text-muted action" onClick={toggleCommentsVisibility}>
                          {showComments ? <>Hide Comments</> : (
                              <>
                                  <i className="bi bi-chat-left-quote"></i> {post.CommentCount} comments...
                              </>
                          )}
                      </span>
                  ) : (
                      post.CommentCount > 0 && post.CommentCount > countNestedPosts(post) ? (
                        <>
                          
                            <span
                              className="load-comments text-muted action"
                              id={`link_${post.PostHashHex}`}
                              data-posthashhex={post.PostHashHex}
                              onClick={showComments ? toggleCommentsVisibility : loadComments}
                          >
                              {showComments ? <>Hide Comments</> : <><i className="bi bi-chat-left-quote"></i> {post.CommentCount} comments...</>}
                          </span>
                          
                        </>
                  ) : null 
                  )}
              </div>
              {showPost ? <div className='text-muted ms-auto'><Link to={`/posts/${post.PostHashHex}`} className='text-muted'><i className='bi bi-post-fill'></i> View Post</Link></div> : null }
              <div className='ms-5 text-muted action' onClick={(e) => toggleReplyForm(e)}>
                  {showReplyForm ? <><i className='bi bi-x-lg'></i> Cancel Reply</> : <><i className='bi bi-reply-fill'></i> Reply</> }
              </div>
          </div>
          {showReplyForm && (
              <ReplyForm post={post} toggleReplyForm={toggleReplyForm} refreshPostComments={refreshPostComments} />
          )}
          {/* Render comments if they are visible */}
          {showComments && (
              <>
                  {comments.length > 0 && (
                      <>
                          {comments.map((comment, index) => (
                              <Comment key={index} comment={comment} currentUser={currentUser} type='comment' level={level+1} accessGroups={accessGroups} preferences={preferences} thread={thread} />
                          ))}
                      </>
                  )}
                  {loadingComments && (
                      <PostPlaceholder feedType={'comments'} />
                      //<Loader /> // Render loader while comments are being fetched
                  )}
                  {loadMoreVisible && (
                      <button onClick={handleLoadMore} disabled={loadingComments}>
                          {loadingComments ? 'Loading...' : `Load More (${post.CommentCount - comments.length} more)`}
                      </button>
                  )}
              </>
          )}
          {showComments && thread && threadBreakOut === false && comments.length > 0 && countNestedPosts(post) < post.CommentCount ? (
              <Link className="text-muted small" onClick={loadCommentsThread}>
                  <>
                      <i className="bi bi-chat-left-quote"></i> {post.CommentCount - countNestedPosts(post)} more comments outside this thread...
                  </>
              </Link>
          ) : null}
      </>
  );
};

export const Comment = ({ comment, currentUser, level, accessGroups, preferences, thread }) => {
  const [formattedComment, setFormattedComment] = useState(null);

  /*
  useEffect(() => {
      const formatComment = async () => {
          try {
              const formatted = await formatPost(comment, currentUser, level, "comment", 'list', accessGroups, preferences, thread);
              setFormattedComment(formatted);
          } catch (error) {
              //console.error('Error formatting comment:', error);
          }
      };

      formatComment();

      // Clean up function
      return () => {
          setFormattedComment(null); // Reset formatted comment on unmount
      };
  }, [comment]);

  return (formattedComment);
  */

  return ( <Reply post={comment} currentUser={currentUser} level={level} type='reply' view={null} accessGroups={accessGroups} preferences={preferences} thread={thread} preview={false} />)
};

export const countNestedPosts = (post) => {
  let count = 0;
  const visitedPosts = new Set();

  const countPostsRecursive = (parentComment) => {
      if (!parentComment || !parentComment.Comments || parentComment.Comments.length === 0) {
          return;
      }

      // Check for circular references
      if (visitedPosts.has(parentComment)) {
          console.warn("Circular reference detected:", parentComment);
          return;
      }

      visitedPosts.add(parentComment);

      count += parentComment.Comments.length;

      for (let i = 0; i < parentComment.Comments.length; i++) {
          countPostsRecursive(parentComment.Comments[i]);
      }
  };

  // Start counting from the root post
  countPostsRecursive(post);

  //console.log("[posts.js] countNestedPosts: ", count, post);
  return count;
};