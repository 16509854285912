import React, { useState, useEffect, useRef, useContext } from "react";
import Deso, { ERROR_TYPES, identity, submitPost, sendDeso, sendDiamonds as sendDiamondsAPI, updateLikeStatus as sendLikeAPI, updateFollowingStatus, blockPublicKey, createPostAssociation, getPostAssociations, deletePostAssociation, constructSendDeSoTransaction, DIAMOND_LEVEL_MAP, createUserAssociation, deleteUserAssociation, getUserAssociations, countPostAssociations, createAccessGroup, updateAccessGroup, updateAccessGroupMembers, addAccessGroupMembers, getBulkAccessGroups, encrypt, getPaginatedAccessGroupMembers, buyCreatorCoin, sellCreatorCoin, transferCreatorCoin, getHodlersForUser, removeAccessGroupMembers } from "deso-protocol"; // Import sendDiamonds from deso-protocol
import { DeSoIdentityContext } from "react-deso-protocol";
import { deso_api } from "./graphql";
import { ErrorContext, useError } from "../contexts/ErrorHandling";
import { Modal, Button, Spinner, Alert, Form, Badge, InputGroup, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Avatar, UsernameComponent } from "./layouts";
import { Post } from "../routes/posts";
import ExchangeRatesContext, { ExchangeRatesProvider } from "../contexts/ExchangeRatesContext";
import { useUserPreferences } from "../contexts/UserPreferences";
import { localCurrency, localToDeso } from "./currency";
import { friendlyFormatDate } from "./helpers";
import { PostButtons, PreviewPost, MediaAttachments, MediaLibrary, addMediaToPost, handleButtonClick, handleChange, handleDeleteEmbed, handleDeleteImage, handleDeleteVideo, handleEmbedInputChange, handleFileChange, handleInsertEmbed, handleInsertImage, handleInsertVideo, handlePaste, handleUpload, processVideo, quillUploadHandler, removeMediaFromPost, toggleEmbedInput, toggleMediaLibrary } from "./compose";
import markdownToText from 'markdown-to-text';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Turndown from 'turndown';
import he from 'he';
import { AccessGroupsContext } from "../contexts/AccessGroups";
import { AppDataContext } from "../contexts/appData";
import { Link } from "react-router-dom";
import { PostSettings } from "./layouts_posts";
import { ModerationModal } from "./moderation";
import { UserInput } from "./profiles";
import { calculateHHI } from "../components/ProfileStats";

const systemKey = process.env.REACT_APP_SYSTEM_KEY;
const gatingKey = process.env.REACT_APP_GATING_KEY;

export const localFees = (TxnValue) => {
    let amountNanos;
    if (!TxnValue || TxnValue === 0) {
        amountNanos = 1500;
    } else {
        const calculatedAmount = Math.floor(TxnValue * 0.01);
        amountNanos = calculatedAmount < 100 ? 100 : calculatedAmount; 
    }
    const payload = {
        PublicKeyBase58Check: "BC1YLg6ZPmoFXwdRMe7EhnuJJJmbckATPpbK3Cy9miH6WSE4UWBzz2u",
        AmountNanos: amountNanos,
    };
    return payload;
}

/*****************************************************
 * Error Handling
 *
export const errorMessages = {
    "RuleErrorCreatorCoinTransferPostAlreadyHasSufficientDiamonds": {
        "title": "Error sending diamonds",
        "description": "You have already awarded this post with the chosen diamond level. You need to issue a higher number of diamonds.",
        "icon": <i key="1" className="bi bi-gem"></i>,
    },
    "RuleErrorFollowEntryAlreadyExists": {
        "title": "Error changing following status",
        "description": "You are trying to set the following status to the same as it is currently set.",
        "icon": <i key="1" className="bi bi-person-fill"></i>,
    }
}
const findErrorMessage = (errorMessage) => {
    console.log("findErrorMessage:",errorMessage);
    const parts = errorMessage.split(':');
    const lastPart = parts[parts.length - 1].trim();
    console.log("using error id:",lastPart);
    const customMessage = errorMessages[lastPart];
    console.log("Found error:",customMessage);
    // If a custom message is found, return it, otherwise use the default message
    if (customMessage) {
        return customMessage;
    } else {
        return { title: 'Error', description: lastPart };
    }
};

export const ErrorModal = ({ error, onClose, onRetry }) => {
    return (
        <div className="modal position-absolute top-0 start-0" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="modal-title">"Houston, we have a problem..."</h5>
                        <i className="ms-auto bi bi-x-lg p-2" onClick={onClose} aria-label="Close"></i>
                    </div>
                    <div className="modal-body border-0 text-center">
                        <h5>{error.title}</h5>
                        <p>{error.description}</p>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-primary" onClick={onRetry}>Retry</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

/********************************************************
 * Send Diamonds
 */

const sendDiamonds = async (DiamondLevel, PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check) => {
    const amountNanos = DIAMOND_LEVEL_MAP[DiamondLevel] || 0; 
    const request = {
        "ReceiverPublicKeyBase58Check": ReceiverPublicKeyBase58Check,
        "SenderPublicKeyBase58Check": SenderPublicKeyBase58Check,
        "DiamondPostHashHex": PostHashHex,
        "DiamondLevel": DiamondLevel,
        "MinFeeRateNanosPerKB": 1000,
        "InTutorial": false,
        TransactionFees: [ localFees(amountNanos) ],
    };

    try {
        const response = await sendDiamondsAPI(request);
        return response;
    } catch (error) {
        throw error;
    }
};
/*
export const SendDiamondsButton = ({ DiamondLevel, PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check, post }) => {
    const { handleError } = useError(); // Get the handleError function from ErrorContext
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const diamondRef = useRef(null);
    const [diamondCount, setDiamondCount] = useState(post.DiamondCount);
    const [diamondClass, setDiamondClass] = useState('');
    
    useEffect(() => {
        if (success && diamondRef.current) {
            diamondRef.current.classList.add('text-info');
            setDiamondClass('text-info');
            const updatedDiamondCount = post.DiamondCount + DiamondLevel;
            setDiamondCount(updatedDiamondCount);
            setSuccess(false);
        } else if (post.PostEntryReaderState && post.PostEntryReaderState.DiamondLevelBestowed > 0) {
            setDiamondClass('text-info');
        }
    }, [success, DiamondLevel, post]);

    const handleSendDiamonds = async () => {
        if (!SenderPublicKeyBase58Check) {
            return;
        }
        setLoading(true);
        try {
            await sendDiamonds(DiamondLevel, post.PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check);
            setSuccess(true);
        } catch (error) {
            handleError(error.message); // Handle error using the handleError function
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className={`flex-fill align-self-center send_diamond mx-1 ${diamondClass}`}>
            {loading ? (
                <>
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>&nbsp;
                </>
            ) : (
                <span>{diamondCount}&nbsp;</span>
            )}
            <i ref={diamondRef} className={`bi bi-gem`} onClick={handleSendDiamonds}></i>
        </div>
    );
};
*/
export const SendDiamondsButton = ({ DiamondLevel, PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check, post }) => {
    const { handleError } = useError();
    const { preferences } = useUserPreferences();
    const { exchangeRates } = useContext(ExchangeRatesContext);
    const diamondRef = useRef(null);
    const [diamondCount, setDiamondCount] = useState(post?.DiamondCount);
    const [diamondClass, setDiamondClass] = useState(post?.PostEntryReaderState?.DiamondLevelBestowed ? 'text-info' : '');
    const [userDiamonds, setUserDiamonds] = useState(post?.PostEntryReaderState?.DiamondLevelBestowed);
    const [showDiamondsPopover, setShowDiamondsPopover] = useState(false);
    const [hoverDiamondLevel, setHoverDiamondLevel] = useState(0);
    const [currentTouchDiamondLevel, setCurrentTouchDiamondLevel] = useState(null);
    const [manualDiamondLevel, setManualDiamondLevel] = useState(5);
    const [showDiamondModal, setShowDiamondModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showShootingStars, setShowShootingStars] = useState(false);

    const shootingStars = () => {
        setShowShootingStars(true);
        setTimeout(() => {
          setShowShootingStars(false);
        }, 5000); // Duration of the animation
      };

    const triggerConfirmationModal = () => {
        setHoverDiamondLevel(null);
        setManualDiamondLevel(5);
        setShowDiamondModal(true);
    }

    const handleSendDiamonds = async (level, confirm) => {
        if (!SenderPublicKeyBase58Check) return;
        if (level > 4 && !confirm) {
            triggerConfirmationModal();
            return;
        }
        setLoading(true);
        try {
            await sendDiamonds(level, post.PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check);
            setDiamondCount(prev => prev + level);
            setUserDiamonds(level);
            setDiamondClass('text-info');
            setShowDiamondsPopover(false);
            shootingStars();
        } catch (error) {
            handleError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTouchStart = (event) => {
        setShowDiamondsPopover(true);
        setCurrentTouchDiamondLevel(null);
        event.preventDefault();
    };

    const handleTouchMove = (event) => {
        const touch = event.touches[0];
        const target = document.elementFromPoint(touch.clientX, touch.clientY);
        const diamondOption = target?.closest('.diamondoption');
        if (diamondOption) {
            const level = parseInt(diamondOption.getAttribute('data-diamond-level'));
            setCurrentTouchDiamondLevel(level);
        } else {
            setCurrentTouchDiamondLevel(null);
        }
    };

    useEffect(() => {
        const handleTouchMoveWithOptions = (event) => handleTouchMove(event);
        document.addEventListener('touchmove', handleTouchMoveWithOptions, { passive: false });

        return () => document.removeEventListener('touchmove', handleTouchMoveWithOptions);
    }, []);

    const handleTouchEnd = (event) => {
        if (currentTouchDiamondLevel) handleSendDiamonds(currentTouchDiamondLevel);
        setShowDiamondsPopover(false);
        setCurrentTouchDiamondLevel(null);
        event.preventDefault();
    };

    const closeDiamondModal = () => {
        setShowDiamondModal(false);
        setManualDiamondLevel(0); 
    };
    return (
        <div className={`align-self-center engagementAction`}>
            <ShootingStars trigger={showShootingStars} />
            {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <div
                    className={`align-self-center send_diamond mx-1 action`}
                    onMouseEnter={() => setShowDiamondsPopover(true)}
                    onMouseLeave={() => setShowDiamondsPopover(false)}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {diamondCount}&nbsp;
                    <i ref={diamondRef} className={`bi bi-gem ${diamondClass}`} aria-label="Send Diamonds"></i>
                    {showDiamondsPopover && ReceiverPublicKeyBase58Check !== SenderPublicKeyBase58Check && (
                        <div
                            className="d-flex rounded-5 bg-body-secondary diamonds-popover flex-row lh-1 translate-middle-y"
                            style={{ zIndex: "1000" }}
                            onMouseEnter={() => setShowDiamondsPopover(true)}
                            onMouseLeave={() => setShowDiamondsPopover(false)}
                            onTouchStart={(e) => e.preventDefault()}
                        >
                            {[1, 2, 3, 4].map((level) => (
                                <div
                                    key={level}
                                    data-diamond-level={level}
                                    className={`p-1 diamondoption lh-1 ${userDiamonds >= level || hoverDiamondLevel >= level ? ' text-info selected' : ''} ${currentTouchDiamondLevel && currentTouchDiamondLevel >= level ? 'text-info touch' : ''}`}
                                    onMouseEnter={() => setHoverDiamondLevel(level)}
                                    onTouchStart={(e) => {
                                        setCurrentTouchDiamondLevel(level);
                                        e.preventDefault();
                                    }}
                                    onTouchEnd={(e) => {
                                        handleSendDiamonds(level);
                                        setCurrentTouchDiamondLevel(null);
                                        setShowDiamondsPopover(false);
                                        e.preventDefault();
                                    }}
                                    onClick={() => handleSendDiamonds(level)}
                                >
                                    <div className="diamond display" data-diamond-level={level}>
                                        <i className={`bi bi-gem diamond-level-icon`}></i>
                                        <div data-diamond-level={level} className="mt-0 diamond-value rounded-3 bg-body-secondary">
                                            {localCurrency((0.000005 * Math.pow(10, level)), preferences, exchangeRates, true)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                key={5}
                                data-diamond-level={5}
                                className={`p-1 diamondoption lh-1 ${userDiamonds >= 5 || hoverDiamondLevel >= 5 ? ' text-info selected' : ''} ${currentTouchDiamondLevel && currentTouchDiamondLevel >= 5 ? 'text-info touch' : ''}`}
                                onMouseEnter={() => setHoverDiamondLevel(5)}
                                onTouchStart={(e) => {
                                    setCurrentTouchDiamondLevel(5);
                                    e.preventDefault();
                                }}
                                onTouchMove={(e) => e.preventDefault()}
                                onTouchEnd={(e) => {
                                    handleSendDiamonds(5);
                                    setCurrentTouchDiamondLevel(null);
                                    setShowDiamondsPopover(false);
                                    e.preventDefault();
                                }}
                                onClick={() => handleSendDiamonds(5)}
                            >
                                <div className="diamond display" data-diamond-level={5}>
                                    <div data-diamond-level={5} className="mt-0">
                                        <div className="morediamonds">
                                            <i className={`bi bi-gem`}></i>
                                            <i className={`bi bi-gem`}></i>
                                            <i className={`bi bi-gem`}></i>
                                            <br/>
                                            <i className={`bi bi-gem`}></i>
                                            <i className={`bi bi-gem`}></i>
                                        </div>
                                        <div className="mt-0 diamond-value rounded-3 bg-body-secondary">more...</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Modal show={showDiamondModal} onHide={closeDiamondModal} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
                <Modal.Body>
                    <div className="mb-3 d-flex flex-row flex-nowrap justify-content-between">
                        <div className="d-flex flex-column">
                            <div className="fw-light d-flex flex-row flex-nowrap">
                                <span className='pe-2 fw-bold'><i className={`mb-0 bi bi-gem me-1`}></i>Send Diamonds</span>
                            </div>
                        </div>
                        <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={closeDiamondModal}>
                            <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
                        </span>
                    </div>
                    <p className="text-center">Choose diamond level</p>
                    <div className="d-flex flex-row flex-nowrap justify-content-evenly lh-1 mb-3">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((level) => (
                            <div
                                key={level}
                                className={`text-center diamondoption lh-1 ${(level <= manualDiamondLevel) ? ' text-info selected' : ''}${(level === manualDiamondLevel) ? ' active' : ''}`}
                                onClick={() => setManualDiamondLevel(level)}
                            >
                                <div className="diamond display">
                                    <i className={`mb-0 bi bi-gem diamond-level-icon`}></i>
                                    <div className="mt-0 diamond-value">{level}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="text-center">You are Sending</p>
                    <p className="text-center">
                        <Avatar type='avatar' publicKey={post.PosterPublicKeyBase58Check} />
                        <Avatar publicKey={post.PosterPublicKeyBase58Check} />
                    </p>
                    <p className="text-center">
                        {[...Array(manualDiamondLevel)].map((_, index) => (
                            <i key={index} className={`mb-0 bi bi-gem diamond-level-icon text-info`}></i>
                        ))}
                        <br />
                        {manualDiamondLevel} diamonds
                    </p>
                    <p className="text-center fw-bold">
                        {localCurrency((0.000005 * Math.pow(10, manualDiamondLevel)), preferences, exchangeRates, true)}
                    </p>
                </Modal.Body>
                <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                    <button className="flex-fill btn btn-outline-secondary" onClick={closeDiamondModal}>Cancel</button>
                    <button className="flex-fill btn btn-primary" onClick={() => handleSendDiamonds(manualDiamondLevel)}>Confirm</button>
                </div>
            </Modal>
        </div>
    );
};



/***************************************************
 * Follow
 */

const followUser = async (followAction, currentUser, ReceiverPublicKeyBase58Check) => {
    const request = {
        "IsUnfollow": followAction,
        "FollowedPublicKeyBase58Check": ReceiverPublicKeyBase58Check,
        "FollowerPublicKeyBase58Check": currentUser.PublicKeyBase58Check,
        TransactionFees: [ localFees() ],
      };

    try {
        const response = await updateFollowingStatus(request);
        return response;
    } catch (error) {
        throw error;
    }
};

export const FollowUser = ({ target, currentUser }) => {
    const [following, setFollowing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to control the confirmation modal
    const { handleError } = useContext(ErrorContext); // Access the handleError function from ErrorContext

    useEffect(() => {
        // Check if ReceiverPublicKeyBase58Check is in the currentUser's following array
        if (
            currentUser &&
            currentUser.PublicKeysBase58CheckFollowedByUser &&
            currentUser.PublicKeysBase58CheckFollowedByUser.includes(target.PublicKeyBase58Check)
        ) {
            setFollowing(true);
        } else {
            setFollowing(false);
        }
    }, [currentUser, target]);

    // Function to handle unfollowing action
    const handleFollowAction = async () => {
        setLoading(true);
        try {
            // Call the followUser function to update following status
            await followUser(following, currentUser, target.PublicKeyBase58Check);
            // Update following state
            setFollowing(!following);
        } catch (error) {
            console.error('Error unfollowing user:', error);
            handleError(error.message); // Handle error using the handleError function
        } finally {
            setLoading(false);
            setShowConfirmationModal(false); // Close the confirmation modal
        }
    };

    return (
        <>
            <li>
                <span className="dropdown-item action" onClick={() => {
                // Show confirmation modal only if unfollowing a user
                if (following) setShowConfirmationModal(true);
                else handleFollowAction(); // Directly perform following action
            }}>
                    <i className={`bi bi-person-fill me-2 ${following ? `text-danger` : ``}`}></i>
                    {following ? `Unfollow` : `Follow`} {target.Username}
                </span>
            </li>
            {showConfirmationModal && (
                <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} style={{ zIndex: "1050", marginTop: "3em" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-0 p-0 py-3">
                        <div className="text-center fs-3">You are <span className="fw-bold">Unfollowing</span></div>
                        <div className="text-center fs-1 my-2"><Avatar type={"avatar"} suppliedProfile={target} publicKey={target.PublicKeyBase58Check} /></div>
                        <div className="text-center fs-3 fw-bold"><Avatar type={"username"} suppliedProfile={target} publicKey={target.PublicKeyBase58Check} /></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Cancel</button>
                        <button className="btn btn-primary" onClick={handleFollowAction}>Confirm</button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export const FollowUserButton = ({ ReceiverPublicKeyBase58Check, label = false, currentUser, className='btn-sm mx-1', size = 'md' }) => {
    const [following, setFollowing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to control the confirmation modal
    const { handleError } = useContext(ErrorContext); // Access the handleError function from ErrorContext

    useEffect(() => {
        // Check if ReceiverPublicKeyBase58Check is in the currentUser's following array
        if (
            currentUser &&
            currentUser.PublicKeysBase58CheckFollowedByUser &&
            currentUser.PublicKeysBase58CheckFollowedByUser.includes(ReceiverPublicKeyBase58Check)
        ) {
            setFollowing(true);
        } else {
            setFollowing(false);
        }
    }, [currentUser, ReceiverPublicKeyBase58Check]);

    // Function to handle unfollowing action
    const handleUnfollow = async () => {
        setLoading(true);
        try {
            // Call the followUser function to update following status
            await followUser(following, currentUser, ReceiverPublicKeyBase58Check);
            // Update following state
            setFollowing(!following);
        } catch (error) {
            console.error('Error unfollowing user:', error);
            handleError(error.message); // Handle error using the handleError function
        } finally {
            setLoading(false);
            setShowConfirmationModal(false); // Close the confirmation modal
        }
    };

    return (
        <>
            <span className={`btn btn-${ size } ${!following ? 'bg-success' : 'bg-danger'} ${className} bg-opacity-75 text-truncate`} onClick={() => {
                // Show confirmation modal only if unfollowing a user
                if (following) setShowConfirmationModal(true);
                else handleUnfollow(); // Directly perform following action
            }}>
                {loading ? (
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                ) : (
                    <>
                    <i className={`${label ? 'me-1' : null} bi ${!following ? 'bi-person-fill-add' : 'bi-person-fill-check'}`}></i>
                    {label && (following ? (
                        <>Unfollow</>
                    ) : (
                        <>Follow</>
                    ))}
                    </>
                )}
            </span>
            {/* Confirmation modal for unfollowing */}
            {showConfirmationModal && (
                <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} style={{ zIndex: "1050", marginTop: "3em" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-0 p-0 py-3">
                        <div className="text-center fs-3">You are <span className="fw-bold">Unfollowing</span></div>
                        <div className="text-center fs-1 my-2"><Avatar type={"avatar"} publicKey={ReceiverPublicKeyBase58Check} /></div>
                        <div className="text-center fs-3 fw-bold"><Avatar type={"username"} publicKey={ReceiverPublicKeyBase58Check} /></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Cancel</button>
                        <button className="btn btn-primary" onClick={handleUnfollow}>Confirm</button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};
/***************************************************
 * User Moderation
 */
export const UserModeration = ({ post, currentUser, preferences, target, label = false, className='', size = 'md' }) => {
    const [loading, setLoading] = useState(false);
    const { handleError } = useContext(ErrorContext); // Access the handleError function from ErrorContext
    const [copiedKey, setCopiedKey] = useState(false);

    const handleCopyPublicKey = () => {
        if (target && target.PublicKeyBase58Check) {
            navigator.clipboard.writeText(target.PublicKeyBase58Check)
                .then(() => {
                    console.log('Public key copied to clipboard:', target.PublicKeyBase58Check);
                    setCopiedKey(true);
                    setTimeout(() => setCopiedKey(false), 5000);
                })
                .catch(err => {
                    console.error('Failed to copy public key:', err);
                });
        }
    };

    return (
        <>  
            
                    <span className={`btn btn-${ size } ${className} drop-down toggle action`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className={`${label ? 'me' : null} bi bi-three-dots-vertical`}></i>
                    </span>
                    <ul className="dropdown-menu small position-absolute p-0">
                        {post && (<PostSettings post={post} currentUser={currentUser} preferences={preferences} />)}
                        <li><Link className="dropdown-item text-nodecoration action" to={`/u/${target.Username}`}><i className="bi bi-person-fill me-2"></i>View Profile</Link></li>
                        {currentUser.PublicKeyBase58Check !== target.PublicKeyBase58Check && (<FollowUser target={target} currentUser={currentUser} />)}
                        <BuyCoin target={target} currentUser={currentUser} />
                        <li><span className={`dropdown-item action ${copiedKey ? `text-muted` : ``}`} onClick={handleCopyPublicKey}><i className={`bi ${copiedKey ? `bi-check-circle-fill text-muted` : `bi-copy`} me-2`}></i>{copiedKey ? `copied key` : `Copy public key`}</span></li>
                        <ModerationTopics post={post} target={target} currentUser={currentUser} />
                        {currentUser.PublicKeyBase58Check !== target.PublicKeyBase58Check && (<ModerationVerification post={post} target={target} currentUser={currentUser} />)}
                        <ModerationFlags post={post} target={target} currentUser={currentUser} />
                        {currentUser.PublicKeyBase58Check !== target.PublicKeyBase58Check && (<BlockUserButton target={target} currentUser={currentUser} label={true} />)}
                    </ul>
            
        </>
    );
};

/*******************************************************************************
 * Coin Transactions
 */
export const BuyCoin = ({ target, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
  
    return (
      <>
        <li>
            <span className="dropdown-item action" onClick={() => setShowModal(true)}>
                <i className="bi bi-coin me-2"></i>
                Buy creator coin
            </span>
        </li>
        {showModal && (
          <BuyCoinModal
            target={target}
            currentUser={currentUser}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
};

const BuyCoinModal = ({ target, currentUser, show, onClose }) => {
    const [coinType, setCoinType] = useState('Creator Coin');
    const [coinAction, setCoinAction] = useState('buy');
    const [loading, setLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [amount, setAmount] = useState(0);
    const { preferences } = useUserPreferences();
    const { exchangeRates } = useContext(ExchangeRatesContext);
    const [coinHolders, setCoinHolders] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [value, setValue] = useState(0);
    const [currencyValue, setCurrencyValue] = useState(0);
    const [buyCoinValue, setBuyCoinValue] = useState(0);

    const handleCustomAmountClick = () => {
        setValue(0); 
    };

    const handleDesoAmountChange = (value) => {
        setValue(value); // Set the custom DESO amount
        // Convert DESO amount to local currency and update local currency input
        const localCurrencyValue = localCurrency(value, preferences, exchangeRates, null, true);
        setCurrencyValue(localCurrencyValue);
    };

    const handleLocalCurrencyAmountChange = (value) => {
        // Convert local currency amount to DESO and update DESO input
        const desoValue = localToDeso(value, preferences, exchangeRates);
        setValue(desoValue);
        setCurrencyValue(value); // Set the custom local currency amount
    };

    const handleCoinActionChange = (key) => {
        setCoinAction(key);
        setConfirmationMessage(null); // Clear previous confirmation message
        setErrorMessage(null); // Clear previous error message
    };

    const submit = async () => {
        return;
        setLoading(true);
        setConfirmationMessage(null);
        setErrorMessage(null);

        try {
            // Build the payload based on coinAction
            const payload = {
                amount: amount,
                userPublicKey: currentUser.PublicKeyBase58Check,
                targetPublicKey: target.PublicKeyBase58Check,
            };

            let response;

            // Call the appropriate function based on the selected action
            if (coinAction === 'buy') {
                response = await buyCreatorCoin(payload);
            } else if (coinAction === 'sell') {
                response = await sellCreatorCoin(payload);
            } else if (coinAction === 'transfer') {
                response = await transferCreatorCoin(payload);
            }

            // Handle success response
            if (response?.success) {
                setConfirmationMessage('Transaction was successful!');
            } else {
                setErrorMessage('There was an error processing the transaction.');
            }
        } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const buyCreatorCoin = async (payload) => {
        // Placeholder for your API call to buy creator coin
        console.log('Buying creator coin:', payload);
        return { success: true }; // Simulate success response
    };

    const sellCreatorCoin = async (payload) => {
        // Placeholder for your API call to sell creator coin
        console.log('Selling creator coin:', payload);
        return { success: true }; // Simulate success response
    };

    const transferCreatorCoin = async (payload) => {
        // Placeholder for your API call to transfer creator coin
        console.log('Transferring creator coin:', payload);
        return { success: true }; // Simulate success response
    };

    useEffect(() => {
        if (!target?.PublicKeyBase58Check) return;

        const fetchHodlers = async () => {
            try {
                const data = await getHodlersForUser({ 
                    PublicKeyBase58Check: target.PublicKeyBase58Check, 
                    FetchAll: true 
                });
                console.log("Coin Holders:", data);
                setCoinHolders(data?.Hodlers || []);
                setRefresh(false);
            } catch (error) {
                console.error("Error fetching coin holders:", error);
            }
        };

        fetchHodlers();
    }, [refresh, target]);

    console.log("CURRENT, TARGET USER:",currentUser, target);

    const holding = currentUser?.UsersYouHODL?.find(
        (holder) => holder.CreatorPublicKeyBase58Check === target.PublicKeyBase58Check
    );
    
    const numberOfCoins = holding ? (holding.BalanceNanos / 1e9) : 0;
    const heldCoinValue = holding ? (numberOfCoins * target.CoinPriceDeSoNanos / 1e9) : 0;
    

    return (
        <Modal show={show} onHide={onClose} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
            <Modal.Body>
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <h5 className="modal-title">
                        <Avatar type="avatar" suppliedProfile={target} publicKey={target.PublicKeyBase58Check} />
                        <Avatar type="avatar" suppliedProfile={target} publicKey={target.PublicKeyBase58Check} type="username" />
                        {coinType}
                    </h5>
                    <button type="button" className="btn-close" onClick={onClose}></button>
                </div>

                {/* Tabs for Buy, Sell, Transfer */}
                <h4>DO NOT USE - IN TESTING</h4>

                <div className="d-flex flex-row align-items-center mb-2">
                    <div className="col-6 px-2 text-end">Current Coin Price</div>
                    <div className="col-6 px-2 lh-1">{localCurrency(target.CoinPriceDeSoNanos / 1e9, preferences, exchangeRates)}<br/><span className="text-muted small">{(target.CoinPriceDeSoNanos / 1e9).toFixed(2)} DESO</span></div>
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                    <div className="col-6 px-2 text-end">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip><span className="p-2"><strong>Founder's reward</strong> is a percentage of any purchase that the creator receives as a reward into their wallet.</span></Tooltip>}
                        >
                            <span>
                                Founders Reward
                                <i className="ms-2 bi bi-info-circle-fill"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div className="col-6 px-2 lh-1">{(target.CoinEntry.CreatorBasisPoints/100).toFixed(2)}%</div>
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                    <div className="col-6 px-2 text-end">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip><span className="p-2">The total number of coins in circulation.</span></Tooltip>}
                        >
                            <span>
                                Circulation
                                <i className="ms-2 bi bi-info-circle-fill"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div className="col-6 px-2">
                        {(target.CoinEntry.CoinsInCirculationNanos/1e9).toFixed(2)} coins
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                    <div className="col-6 px-2 text-end">Holders</div>
                    <div className="col-6 px-2">{target.CoinEntry.NumberOfHolders} users</div>
                </div>
                <div className="d-flex flex-row align-items-center mb-3">
                    <div className="col-6 px-2 text-end">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip><span className="p-2"><strong>Herfindahl-Hirschman Index (HHI)</strong> indicates coin holder concentration. A low figure indicates more evenly distributed circulation.<br/>5 holders each holding 20% of supply would be 0.20<br/>1 holding 80% with remainder split between 4 others would give 0.65</span></Tooltip>}
                        >
                            <span>
                                HHI 
                                <i className="ms-2 bi bi-info-circle-fill"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div className="col-6 px-2">{!refresh ? calculateHHI(coinHolders, target.CoinEntry.CoinsInCirculationNanos).toFixed(2) : <Spinner size="sm" />}</div>
                </div>

                {numberOfCoins ? (
                    <>
                        <h4>Your Holding</h4>
                        <div className="d-flex flex-row align-items-center mb-2">
                            <div className="col-6 px-2 text-end">
                                Currently Holding
                            </div>
                            <div className="col-6 px-2">{numberOfCoins.toFixed(5)} coins</div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-2">
                            <div className="col-6 px-2 text-end">
                                Value
                            </div>
                            <div className="col-6 px-2 lh-1">
                                {localCurrency(heldCoinValue, preferences, exchangeRates)}<br/><span className="text-muted small">{(heldCoinValue).toFixed(2)} DESO</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="my-3 fw-bold text-center">You do not hold {target.Username}'s coin</div>
                    </>
                )}

                {target.CoinEntry.CoinsInCirculationNanos > 0 && target.CoinEntry.CreatorBasisPoints !== 10000 ? (
                    <Tabs
                        id="coin-action-tabs"
                        activeKey={coinAction}
                        onSelect={handleCoinActionChange}
                        className="my-3"
                    >
                        <Tab eventKey="buy" title="Buy" disabled={target.CoinEntry.CreatorBasisPoints === 10000}>
                            {/* Additional UI for buy transaction, e.g., amount input */}
                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                <div className="col-6 pe-2 mb-3">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={value} onChange={(event) => handleDesoAmountChange(event.target.value)} />
                                        <span className="input-group-text">DESO</span>
                                    </div>
                                </div>
                                <div className="col-6 ps-2 mb-3">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={currencyValue} onChange={(event) => handleLocalCurrencyAmountChange(event.target.value)} />
                                        <span className="input-group-text">{preferences.currency}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center mb-3">- or -</div>
                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={buyCoinValue} onChange={(event) => setBuyCoinValue(event.target.value)} />
                                        <span className="input-group-text">{target.Username} coin(s)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-items-center mb-2">
                                <div className="col-6 px-2 text-end">
                                    You receive
                                </div>
                                <div className="col-6 px-2">XYZ coins</div>
                            </div>
                            <div className="d-flex flex-row align-items-center mb-2">
                                <div className="col-6 px-2 text-end">
                                    Creator receives
                                </div>
                                <div className="col-6 px-2">XYZ DESO</div>
                            </div>
                        </Tab>
                        <Tab eventKey="sell" title="Sell" disabled={numberOfCoins === 0}>
                            {/* Additional UI for sell transaction, e.g., amount input */}
                            <div>
                                <label>Amount to Sell</label>
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="transfer" title="Transfer" disabled={numberOfCoins === 0}>
                            {/* Additional UI for transfer transaction, e.g., amount input */}
                            <div>
                                <label>Amount to Transfer</label>
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </Tab>
                    </Tabs>
                ) : (
                    <div className="my-3 fw-bold text-center">{target.Username}'s coin is not available</div>
                )}

                {/* Display confirmation or error message */}
                {confirmationMessage && <div className="alert alert-success">{confirmationMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </Modal.Body>

            <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={submit}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : `Confirm ${coinAction.charAt(0).toUpperCase() + coinAction.slice(1)}`}
                </button>
            </div>
        </Modal>
    );
};

/*****************************************************************
 * User moderation flags
 */

export const ModerationTopics = ({ post, target, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
  
    return (
      <>
        <li>
          <span className="dropdown-item action" onClick={() => setShowModal(true)}>
            <i className="bi bi-tags-fill me-2"></i>Suggest topic(s)
          </span>
        </li>
        {showModal && (
          <ModerationModal
            mode={'TOPIC'}
            post={post}
            target={target}
            currentUser={currentUser}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  };
  export const ModerationVerification = ({ post, target, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
  
    return (
      <>
        <li>
          <span className="dropdown-item action" onClick={() => setShowModal(true)}>
            <i className="bi bi-patch-check-fill me-2"></i>Verify this user
          </span>
        </li>
        {showModal && (
          <ModerationModal
            mode={'VERIFICATION'}
            post={post}
            target={target}
            currentUser={currentUser}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  };
export const ModerationFlags = ({ post, target, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
  
    return (
      <>
        <li>
          <span className="dropdown-item action" onClick={() => setShowModal(true)}>
            <i className="bi bi-flag-fill me-2"></i>Flag for moderation
          </span>
        </li>
        {showModal && (
          <ModerationModal
            mode={'FLAG'}
            post={post}
            target={target}
            currentUser={currentUser}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  };


/***************************************************
 * Message User
 */

export const MessageUser = ({ ReceiverPublicKeyBase58Check, label = false, className = 'mx-1', size = 'sm' }) => {
    const [loading, setLoading] = useState(false);
    const { initiateChat } = useContext(AccessGroupsContext);
    const { handleError } = useContext(ErrorContext);
  
    const handleInitiateChat = async () => {
      setLoading(true);
      try {
        await initiateChat(ReceiverPublicKeyBase58Check);
      } catch (error) {
        handleError('Failed to initiate chat', error);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <span className={`btn btn-${size} bg-info bg-opacity-75 flex-row flex-nowrap ${className}`} onClick={handleInitiateChat} disabled={loading}>
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <i className={`bi bi-envelope-fill`}></i>
            {label ? <span className="d-none d-md-inline ms-1">Chat</span> : null}
          </>
        )}
      </span>
    );
  };

/***************************************************
 * Block User
 */

const blockUser = async (blockAction, currentUser, ReceiverPublicKeyBase58Check) => {
    const request = {
        "Unblock": blockAction,
        "BlockPublicKeyBase58Check": ReceiverPublicKeyBase58Check,
        "PublicKeyBase58Check": currentUser.PublicKeyBase58Check,
        TransactionFees: [ localFees() ],
      };

    try {
        const response = await blockPublicKey(request);
        return response;
    } catch (error) {
        throw error;
    }
};

export const BlockUserButton = ({ target, currentUser, label = false }) => {
    const [blocked, setBlocked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to control the confirmation modal
    const { handleError } = useContext(ErrorContext); // Access the handleError function from ErrorContext

    useEffect(() => {
        // Check if currentUser exists and BlockedPubKeys is not empty
        if (
            currentUser &&
            Array.isArray(currentUser.BlockedPubKeys) &&
            currentUser.BlockedPubKeys &&
            currentUser.BlockedPubKeys.includes(target.PublicKeyBase58Check)
        ) {
            setBlocked(true);
        } else {
            setBlocked(false);
        }
    }, [currentUser, target]);

    // Function to handle blocking action
    const handleBlock = async () => {
        setLoading(true);
        try {
            // Call the blockUser function to update blocking status
            await blockUser(blocked, currentUser, target.PublicKeyBase58Check);
            // Update blocking state
            setBlocked(!blocked);
        } catch (error) {
            console.error('Error blocking user:', error);
            handleError(error.message); // Handle error using the handleError function
        } finally {
            setLoading(false);
            setShowConfirmationModal(false); // Close the confirmation modal
        }
    };

    // Function to handle blocking confirmation
    const handleConfirmBlock = () => {
        setShowConfirmationModal(false); // Close the confirmation modal
        handleBlock(); // Call the handleBlock function to perform the blocking action
    };

    return (
        <>
            <li onClick={() => {
                // Show confirmation modal only if blocking a user
                if (!blocked) setShowConfirmationModal(true);
                else handleBlock(); // Directly perform unblocking action
            }}>
                <span className="dropdown-item action">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <i className={`me-2 bi ${blocked ? 'bi-person-check text-success' : 'bi-dash-circle-fill text-danger'}`}></i>
                        {label && (blocked ? (
                            <>
                                Unblock&nbsp;{target?.Username ? target.Username : `user`}
                            </>
                        ) : (
                            <>
                                Block&nbsp;{target?.Username ? target.Username : `user`}
                            </>
                        ))} 
                    </>
                )}
                </span>
            </li>
            {showConfirmationModal && (

                <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
                    <Modal.Body>
                        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                            <h5 className="modal-title"><i className={`bi bi-ban me-2`}></i>Confirmation</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmationModal(false)} ></button>
                        </div>
                        <div className="text-center fs-3">You are <span className="fw-bold">Blocking</span></div>
                        <div className="text-center fs-1 my-2"><Avatar type={"avatar"} publicKey={target.PublicKeyBase58Check} suppliedProfile={target} /></div>
                        <div className="text-center fs-3 fw-bold mb-0"><Avatar type={"username"} publicKey={target.PublicKeyBase58Check} suppliedProfile={target} /></div>
                    </Modal.Body>
                    <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                        <button className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)} >Cancel</button>
                        <button className="btn btn-primary" onClick={handleConfirmBlock}>Confirm</button>
                    </div>
                </Modal>
            )}
        </>
    );
};

/********************************************************
 * Send Reaction
 */
export const SendReactionButton = React.memo(({ PostHashHex, SenderPublicKeyBase58Check, ReceiverPublicKeyBase58Check, post }) => {
    const { handleError } = useError();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const buttonRef = useRef(null);
    const [likeCount, setReactionCount] = useState(post?.LikeCount);
    const [reactionClass, setReactionClass] = useState(post?.PostEntryReaderState?.LikedByReader);
    const [selectedReaction, setSelectedReaction] = useState(null);
    const [reactions, setReactions] = useState(null);
    const [showReactions, setShowReactions] = useState(false);
    const [currentTouchReaction, setCurrentTouchReaction] = useState(null);

    useEffect(() => {
        updateReactions(PostHashHex);
    }, [PostHashHex, success]);

    const updateReactions = async (PostHashHex) => {
        try {
            const request = {
                PostHashHex: PostHashHex,
                AssociationType: "REACTION",
            };
            const data = await getPostAssociations(request);
            const updatedReactions = {
                ...data,
                likeCount: likeCount
            };
            const currentUserReactions = data.Associations.filter(association => association.TransactorPublicKeyBase58Check === SenderPublicKeyBase58Check);
            const latestReaction = currentUserReactions.length > 0 ? currentUserReactions[currentUserReactions.length - 1] : null;
            setSelectedReaction(latestReaction ? latestReaction : null);
            setReactions(updatedReactions);
            setLoading(false);
        } catch (error) {
            //handleError(error.message);
        }
    }

    const reactionToClass = {
        'LOVE': 'heart-fill text-danger',
        'LAUGH': 'emoji-laughing-fill text-warning',
        'LIKE': 'hand-thumbs-up-fill text-warning',
        'ASTONISHED': 'emoji-astonished-fill text-warning',
        'DISLIKE': 'hand-thumbs-down-fill text-warning',
        'SAD': 'emoji-tear-fill text-warning',
        'ANGRY': 'emoji-angry-fill text-danger',
    }

    const handleSendReaction = async (value) => {
        if (!SenderPublicKeyBase58Check) {
            return;
        }
        if (selectedReaction && value === selectedReaction.AssociationValue) {
            return;
        }
        setLoading(true);

        try {
            if (selectedReaction && value !== selectedReaction.AssociationValue) {
                const deleteRequest = {
                    'TransactorPublicKeyBase58Check': SenderPublicKeyBase58Check,
                    'AssociationID': selectedReaction.AssociationID,
                }
                await deletePostAssociation(deleteRequest);
                setSelectedReaction(null);
            }
            const request = {
                'PostHashHex': PostHashHex,
                'TransactorPublicKeyBase58Check': SenderPublicKeyBase58Check,
                'AssociationType': "REACTION",
                'AssociationValue': value,
                "AppPublicKeyBase58Check": systemKey,
            }
            await createPostAssociation(request);
            setSuccess(true);
            setSelectedReaction(value);
            updateReactions(PostHashHex);
        } catch (error) {
            handleError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const hoverReaction = (newValue) => {
        setShowReactions(newValue);
    };

    const handleTouchStart = (event) => {
        setShowReactions(true);
        setCurrentTouchReaction(null);
        event.preventDefault();
    };

    const handleTouchMove = (event) => {
        const touch = event.touches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);
        if (element && element.classList.contains('reaction')) {
            const reactionType = element.getAttribute('data-reaction');
            setCurrentTouchReaction(reactionType);
        } else {
            setCurrentTouchReaction(null);
        }
        event.preventDefault();
    };

    const handleTouchEnd = (event) => {
        if (currentTouchReaction) {
            handleSendReaction(currentTouchReaction);
        }
        setShowReactions(false);
        setCurrentTouchReaction(null);
        event.preventDefault();
    };

    return (
        <div className={`align-self-center engagementAction`}>
            <span>
                {loading ? (
                    <>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>&nbsp;
                    </>
                ) : (
                    <div 
                        className={`align-self-center send_reaction action mx-1 ${reactionClass}`}
                        onMouseEnter={() => hoverReaction(true)}
                        onMouseLeave={() => hoverReaction(false)}
                        onClick={(event) => {
                            event.preventDefault();
                            setShowReactions(prev => !prev); // Toggle showReactions state
                        }}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        {reactions?.Associations?.length + reactions?.likeCount}&nbsp;
                        <i
                            ref={buttonRef}
                            className={`bi ${selectedReaction ? `bi-${reactionToClass[selectedReaction.AssociationValue]}` : 'bi-heart'}`}
                            aria-label="React"
                        ></i>
                        {showReactions && (
                            <div
                                className="d-flex rounded-5 bg-body-secondary flex-row reactions-popover translate-middle-y"
                                style={{ zIndex: "1000" }}
                                onMouseEnter={() => hoverReaction(true)}
                                onMouseLeave={() => hoverReaction(false)}
                                onTouchStart={(e) => e.preventDefault()} // Prevents scrolling within the popover
                            >
                                {Object.keys(reactionToClass).map((reaction) => (
                                    <div
                                        key={reaction}
                                        onClick={() => handleSendReaction(reaction)}
                                        className={`px-2 reaction ${(selectedReaction && selectedReaction.AssociationValue === reaction) ? 'selected' : ''} ${(currentTouchReaction && currentTouchReaction === reaction) ? 'touch' : ''}`}
                                        data-reaction={reaction}
                                        onTouchStart={(e) => {
                                            setCurrentTouchReaction(reaction);
                                            e.preventDefault();
                                        }}
                                        onTouchMove={(e) => {
                                            e.preventDefault();
                                        }}
                                        onTouchEnd={(e) => {
                                            handleSendReaction(reaction);
                                            setCurrentTouchReaction(null);
                                            setShowReactions(false);
                                            e.preventDefault();
                                        }}
                                    >
                                        <div className="display">
                                            <i className={`bi ${(selectedReaction && selectedReaction.AssociationValue === reaction) && (currentTouchReaction && currentTouchReaction === reaction) ? `bi bi-trash3-fill deletereaction` : `bi-${reactionToClass[reaction]}`}`}></i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </span>
        </div>
    );
});



export const SendRepostButton = ({ currentUser, alternateUsers, PostHashHex, SenderPublicKeyBase58Check, post }) => {
    const { preferences } = useUserPreferences(); 
    //console.log("<SendRepostButton> props:", currentUser, preferences, alternateUsers);
    const { handleError } = useError();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const buttonRef = useRef(null);
    const [repostCount, setRepostCount] = useState(post?.RepostCount)
    const [repostClass, setRepostClass] = useState(post?.PostEntryReaderState?.RepostedByReader);
    const [showOptions, setShowOptions] = useState(false); // State to track if options popover is shown
    const [showRepostModal, setShowRepostModal] = useState(false);

    const [quill, setQuill] = useState(null);
    const [postType, setPostType] = useState('plaintext');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const containerRef = useRef(null); // Initialize containerRef
    const [formData, setFormData] = useState({
        postTitle: null,
        postType: null,
        description: null,
        slug: null,
        RepostedPostEntryResponse: post,
        body: null,
        quoteRepost: false,
    });
    const userLocale = navigator.language || navigator.userLanguage;
    const [modalVisible, setModalVisible] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [ImageURLs, setImageURLs] = useState([]); 
    const [VideoURLs, setVideoURLs] = useState([]);
    const [queuedFiles, setQueuedFiles] = useState([]);
    const [mediaInfo, setMediaInfo] = useState({});
    const [tab, setTab] = useState('content');
    const [imageUploading, setImageUploading] = useState(false);
    const [videoUploading, setVideoUploading] = useState(false);
    const [videoProcessing, setVideoProcessing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputFileRef = useRef(null);
    const [embedUrl, setEmbedUrl] = useState('');
    const [showEmbedInput, setShowEmbedInput] = useState(false);
    const [showMediaLibrary, setShowMediaLibrary] = useState(false);
    const [quoteRepost, setQuoteRepost] = useState( formData && formData.body && formData.body !== '' ? true : false);
    const [tmpBody, setTmpBody] = useState(null);
    const [tmpImageURLs, setTmpImageURLs] = useState([]);
    const [tmpVideoURLs, setTmpVideoURLs] = useState([]);
    const [tmpEmbedUrl, setTmpEmbedUrl] = useState('');

    const textAreaRef = useRef(null);
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto'; // Reset height to auto
            textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight+10, 200)}px`; // Adjust height
        }
      }, [formData.body]); 
    const savePost = false;

    useEffect(() => {
        // Fetch necessary data or perform any initialization when the component mounts
        setRepostClass(post?.PostEntryReaderState?.RepostedByReader || false);
        setLoading(false);
    }, [post]);

    const toggleQuoteRepost = (value) => {
        //console.log("ToggleQuoteRepost: ", value);
        if (!value && formData && formData.body && formData.body !== '') {
            setTmpBody(formData.body);
            setTmpImageURLs(ImageURLs);
            setTmpVideoURLs(VideoURLs);
            setTmpEmbedUrl(embedUrl);
            setImageURLs(null);
            setVideoURLs(null);
            setEmbedUrl('');
            setFormData(prevFormData => ({
                ...prevFormData,
                body: null,
                quoteRepost: quoteRepost,
            }));
        } else if (value && tmpBody) {
            setImageURLs(tmpImageURLs);
            setVideoURLs(tmpVideoURLs);
            setEmbedUrl(tmpEmbedUrl);
            setFormData(prevFormData => ({
                ...prevFormData,
                body: tmpBody,
                quoteRepost: quoteRepost,
            }));
            setTmpBody(null);
            setTmpImageURLs(null);
            setTmpVideoURLs(null);
            setTmpEmbedUrl('');
        }
        setQuoteRepost(value);
    };
    
    useEffect(() => {
        //console.log("QuoteRepost Updated:", formData, ImageURLs, VideoURLs);
    }, [formData]);

    const toggleRepostModal = async () => {
        setShowRepostModal(!showRepostModal);
    };

    const closeRepostModal = async () => {
        setShowRepostModal(false);
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const increaseRepostCount = () => {
        const newRepostCount = (parseInt(repostCount, 10) + 1).toString();
        setRepostCount(newRepostCount);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        /*
        if (!quill) {
            console.error('Quill editor not initialized');
            return;
        }
        */
        setIsSubmitting(true);
    
        let htmlContent;
        let plaintext;
        let markdownContent;
        let delta;

        /*
        if(postType === 'formatted' || postType === 'blog') {
            delta = quill.getContents();
            const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
            htmlContent = converter.convert();
            const turndown = new Turndown();
            markdownContent = turndown.turndown(htmlContent);
            console.log("[modalCompose] processing from html:", htmlContent);
            plaintext = he.decode(htmlContent)
                .replace(/<p\s*\/?>/gi, '\n\n') // Insert two new lines after opening <p> tag
                .replace(/<h\d\s*\/?>/gi, '\n\n') // Insert two new lines after opening <h1>, <h2>, etc. tags
                .replace(/<br\s*\/?>/gi, '\n') // Replace <br> with a single new line
                .replace(/<blockquote\s*\/?>/gi, '\n\n') // Insert two new lines after opening <blockquote> tag
                .replace(/<\/?[^>]+>/g, '') // Remove all other HTML tags
                .replace(/^\s*[\n\r]/, '') // Remove leading new lines
                .replace(/[\n\r]\s*$/, ''); // Remove trailing new lines
        }*/

        const formDataObject = {
            ...formData,
            postBody: htmlContent,
        };

        let payload = {
            UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            BodyObj: {
            },
            RepostedPostHashHex: post.PostHashHex,
            PostExtraData: {
                Node: preferences.nodeId.toString(),
                tool: 'Testing',
                Language: userLocale,
            },
            TransactionFees: [ localFees() ],
        }
    
        if(quoteRepost) {
            switch(postType) {
                case "plaintext":
                    payload.BodyObj.Body = formData && formData.body ? formData.body.trim() : null;
                    break;
                case "formatted":
                    payload.BodyObj.Body = plaintext;
                    payload.PostExtraData.bodyMarkdown = markdownContent;
                    payload.PostExtraData.Title = formDataObject.postTitle;
                    break;
                case "media":
                    break;
                case "blog":
                    payload.BodyObj.Body = plaintext;
                    payload.PostExtraData.bodyMarkdown = markdownContent;
                    payload.PostExtraData.Title = formDataObject.postTitle;
                    payload.PostExtraData.Description = formDataObject.description;
                    payload.PostExtraData.BlogTitleSlug = formDataObject.slug;
                    payload.PostExtraData.BlogDeltaRtfFormat = JSON.stringify(delta.ops);
                    break;
            }
            payload.BodyObj.ImageURLs = ImageURLs;
            payload.BodyObj.VideoURLs = VideoURLs;

        }
        //console.log("[modalCompose.jsx] formData: ",formData);
    
        if(embedUrl) {
            payload.PostExtraData.EmbedVideoURL = embedUrl;
        }
    
        try {
            //console.log("[modalCompose] final post:",payload)
            const resp = await submitPost(payload);
            //let resp;
            //console.log(resp);
            //alert("Post submitted!");
    
            if(resp) {
                // Reset the form
                setFormData({
                    postTitle: '',
                    postType: '',
                    description: '',
                    slug: '',
                    RepostedPostEntryResponse: post,
                });
                //quill.setText('');

                // Update button appearance and label
                setSubmitSuccess(true);
                increaseRepostCount();
                setIsSubmitting(false);
                setImageURLs(null);
                setVideoURLs(null);
                setEmbedUrl(null);
                setSubmitError(false);

                // Reset submit button appearance after 5 seconds
                setTimeout(() => {
                    setShowRepostModal(false);
                    setSubmitSuccess(false);
                }, 4000);
            } else {
                setSubmitSuccess(false);
                setSubmitError(true);
                setIsSubmitting(false);
            }
        } catch (error) {
            //console.error('Error submitting post:', error);
            // Handle error
            setSubmitError(true);
            setIsSubmitting(false);
        }
    };

    return (
        <div 
            className={`align-self-center engagementAction`}
        >
            <span>
                {loading ? (
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                ) : (
                    <div 
                        className={`flex-fill align-self-center send_repost mx-1 action`}
                        onClick={(event) => {
                            event.preventDefault();
                            toggleRepostModal();
                        }}
                    >
                        <span>{repostCount}&nbsp;</span>
                        <i
                            ref={buttonRef}
                            className={`bi bi-repeat ${repostClass ? ' text-success' : null }`}
                            aria-label="Repost/Quote"
                        ></i>
                    </div>
                )}
            </span>
            <Modal show={showRepostModal} onHide={closeRepostModal} centered={true} fullscreen="md-down" size="lg" className="modal-autoheight">
                <Modal.Body>
                    <div className="mb-3 d-flex flex-row flex-nowrap justify-content-between">
                        <div className="d-flex flex-column text-truncate">
                            {alternateUsers && alternateUsers.length > 0 && (
                                <div className="fw-light d-flex flex-row flex-nowrap mb-2">
                                    <div className="ms-1 dropdown">
                                        <span className="dropdown-toggle fw-bold me-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <Avatar type="avatar" publicKey={currentUser.PublicKeyBase58Check} link={false} />
                                            <Avatar publicKey={currentUser.PublicKeyBase58Check} link={false} />
                                        </span>
                                        <ul className="dropdown-menu mt-1">
                                        {alternateUsers.map((user, index) => (
                                            <li className="ps-2" key={index} onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}>
                                            <Avatar type="avatar" publicKey={user.PublicKeyBase58Check} link={false} />
                                            <Avatar publicKey={user.PublicKeyBase58Check} link={false} />
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="fw-light d-flex flex-row flex-nowrap text-truncate">
                                <span className='pe-2'>{quoteRepost ? 'Quoting ' : 'Reposting '}</span>
                                <Avatar type={`avatar`} publicKey={post.PosterPublicKeyBase58Check} /><Avatar publicKey={post.PosterPublicKeyBase58Check} />
                            </div>
                        </div>
                        <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={closeRepostModal}>
                            <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
                        </span>
                    </div>
                    <div className="form-check form-switch mb-3">
                        <input className="form-check-input" type="checkbox" role="switch" id="quoteRepostSwitch" checked={quoteRepost} onClick={(e) => toggleQuoteRepost(e.target.checked)} />
                        <label className="form-check-label" for="quoteRepostSwitch">Quote this post</label>
                    </div>
                    <div className={`collapse ${quoteRepost ? `show` : ``}`}>
                        <div className={`input-group mb-3`}>
                            <textarea 
                                name='body' 
                                ref={textAreaRef}
                                className='w-100 card-effect my-0 inputBody p-2 rounded-3' 
                                value={formData.body} 
                                onChange={(event) => handleChange(event, formData, setFormData)}
                            />
                        </div>
                        <MediaAttachments
                            ImageURLs={ImageURLs}
                            VideoURLs={VideoURLs}
                            quill={quill}
                            handleInsertImage={handleInsertImage}
                            handleDeleteImage={handleDeleteImage}
                            handleInsertVideo={handleInsertVideo}
                            handleDeleteVideo={handleDeleteVideo}
                            queuedFiles={queuedFiles}
                            showEmbedInput={showEmbedInput}
                            embedUrl={embedUrl}
                            setEmbedUrl={setEmbedUrl}
                            handleInsertEmbed={handleInsertEmbed}
                            handleDeleteEmbed={handleDeleteEmbed}
                            handleEmbedInputChange={(event) => handleEmbedInputChange(event, setEmbedUrl)}
                            toggleMediaLibrary={() => toggleMediaLibrary(setShowMediaLibrary)}
                            handleButtonClick={handleButtonClick}
                            inputFileRef={inputFileRef}
                            handleFileChange={(event) => handleFileChange(event, setQueuedFiles, ImageURLs, setImageURLs, VideoURLs, setVideoURLs)}
                            submitError={submitError}
                            submitSuccess={submitSuccess}
                            isSubmitting={isSubmitting}
                            handleSubmit={handleSubmit}
                            setImageURLs={setImageURLs}
                            setVideoURLs={setVideoURLs}
                            setQueuedFiles={setQueuedFiles}
                            mediaInfo={mediaInfo}
                            setMediaInfo={setMediaInfo}
                            postType={'plaintext'}
                            tab={'content'}
                        />
                    </div>
                    
                    <h5>Preview</h5>
                    <PreviewPost currentUser={currentUser} alternateUsers={alternateUsers} preferences={preferences} postType={postType} ImageURLs={ImageURLs} VideoURLs={VideoURLs} mediaInfo={mediaInfo}  quill={quill} userLocale={userLocale} formData={formData} embedUrl={embedUrl} tab={tab} />
                </Modal.Body>


                <div className='row m-0 p-0'>
                    <PostButtons
                        keyId={post.PostHashHex+`_repost`}
                        toggleMediaLibrary={() => toggleMediaLibrary(setShowMediaLibrary)}
                        handleButtonClick={handleButtonClick}
                        inputFileRef={inputFileRef}
                        handleFileChange={(event) => handleFileChange(event, setQueuedFiles, ImageURLs, setImageURLs, VideoURLs, setVideoURLs)}
                        toggleEmbedInput={() => toggleEmbedInput(showEmbedInput, setShowEmbedInput, setEmbedUrl)}
                        submitError={submitError}
                        submitSuccess={submitSuccess}
                        isSubmitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        savePost={null}
                        schedulePost={null}
                        options={quoteRepost ? true : false}
                        label={`${quoteRepost ? 'Quote ' : 'Repost'}`}
                        togglePoll={null}
                        clearMessages={null}
                        showLabels={false}
                        showSubmit={true}
                    />
                </div>
                
            </Modal>
            { showMediaLibrary ? (
            <MediaLibrary
                currentUser={currentUser}
                preferences={preferences}
                toggleMediaLibrary={() => toggleMediaLibrary(setShowMediaLibrary)}
                addMediaToPost={(url, type) => addMediaToPost(url, type, setImageURLs, setVideoURLs)}
                removeMediaFromPost={(url, type) => removeMediaFromPost(url, type, setImageURLs, setVideoURLs)}
                VideoURLs={VideoURLs}
                ImageURLs={ImageURLs}
            />        
        ) : null }
        </div>
    );
};

/*******************************************************
 * Direct Tips
 * Can be triggered from different places
 *  - e.g. on a post or directly from a creator's profile
 */
export const DirectTipButton = ({ SenderPublicKeyBase58Check, Receiver, source = null, PostHashHex = null, size = 'md' }) => {
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [tipAmount, setTipAmount] = useState(0); // State to store selected tip amount
    const [tipNote, setTipNote] = useState(''); // State to store tip note
    const [showCustomAmount, setShowCustomAmount] = useState(false);
    const [customDesoAmount, setCustomDesoAmount] = useState(0); // State to store custom DESO amount
    const [customLocalCurrencyAmount, setCustomLocalCurrencyAmount] = useState(0); // State to store custom local currency amount
    const [loading, setLoading] = useState(false);
    const [tipSuccess, setTipSuccess] = useState(false); // State to manage tip success
    const { preferences } = useUserPreferences();
    const { exchangeRates } = useContext(ExchangeRatesContext);

    const buttonRef = useRef(null);

    const handleTipAction = async () => {
        setLoading(true); // show busy
        const amountNanos = showCustomAmount ? Math.floor(customDesoAmount * 1000000000) : tipAmount;
        const request = {
            SenderPublicKeyBase58Check: SenderPublicKeyBase58Check,
            RecipientPublicKeyOrUsername: Receiver?.PublicKeyBase58Check,
            AmountNanos: amountNanos,
            ExtraData: {
                'isTip': "true",
                'Type': 'Direct Tip',
                'Source': source, // e.g. profile or post/other
                'Note': tipNote, // the note from the sender
            },
            TransactionFees: [ localFees(amountNanos) ],
        };

        if(source === "post" &&  PostHashHex) {
            request.ExtraData["PostHashHex"] = PostHashHex;
        };
        //console.log("[DIRECT TIP] Request:", request);
        try {
            // Send tip request
            await sendDeso(request);
            setTipSuccess(true); // Show success modal
            setShowCustomAmount(false);
            setCustomDesoAmount(0);
            setCustomLocalCurrencyAmount(0);
            setTipAmount(0);
            setTipNote('');
        } catch (error) {
            // Handle error
            console.error("Error sending tip:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };    

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleTipAmountChange = (amount) => {
        setShowCustomAmount(false);
        setTipAmount(amount);
    };

    const handleCustomAmountClick = () => {
        setShowCustomAmount(true); // Show the custom amount input field
        setTipAmount(0); // Reset selected tip amount when custom amount is selected
    };

    const handleCustomDesoAmountChange = (value) => {
        setCustomDesoAmount(value); // Set the custom DESO amount
        // Convert DESO amount to local currency and update local currency input
        const localCurrencyValue = localCurrency(value, preferences, exchangeRates, null, true);
        setCustomLocalCurrencyAmount(localCurrencyValue);
    };

    const handleCustomLocalCurrencyAmountChange = (value) => {
        // Convert local currency amount to DESO and update DESO input
        const desoValue = localToDeso(value, preferences, exchangeRates);
        setCustomDesoAmount(desoValue);
        setCustomLocalCurrencyAmount(value); // Set the custom local currency amount
    };

    const handleTipNoteChange = (event) => {
        setTipNote(event.target.value);
    };

    const handleConfirmTip = () => {
        // Logic to confirm tip
        handleTipAction();
        toggleModal(); // Close the modal after confirming
    };

    const standardTipAmounts = [50000000, 250000000, 500000000, 5000000000];
    return (
        <>
            <span 
                className={`action align-self-center ${source === "Profile" ? `btn btn-${size} bg-info bg-opacity-75` : " engagementAction send_repost"} mx-1`}
                onClick={(event) => {
                    event.preventDefault(); // Prevent default behavior of click event
                    toggleModal(); // Open the modal on button click
                }}
            >
                <span>
                    <i
                        ref={buttonRef}
                        className={`bi bi-cash me-1`}
                        aria-label="Send Tip"
                    ></i> 
                    {source === "Profile" ? "Tip" : null}
                </span>
            </span>

            {SenderPublicKeyBase58Check !== Receiver?.PublicKeyBase58Check ? (
                <Modal show={showModal} onHide={toggleModal} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
                    <Modal.Body>
                        <div className="mb-3 d-flex flex-row flex-nowrap justify-content-between">
                            <div className="d-flex flex-column">
                                <div className="fw-light d-flex flex-row flex-nowrap">
                                    <span className='pe-2 fw-bold'>
                                        <i
                                            ref={buttonRef}
                                            className={`bi bi-cash`}
                                            aria-label="Send Tip"
                                        ></i> Send Direct Tip
                                    </span>
                                </div>
                            </div>
                            <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={toggleModal}>
                                <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                Sending tip to:<br/>
                                <span className="fs-4">
                                    <Avatar type="avatar" publicKey={Receiver.PublicKeyBase58Check} /> <Avatar type="username" publicKey={Receiver.PublicKeyBase58Check} />
                                </span>
                            </div>
                            <p>Choose tip value:</p>
                            {/* Tip amount options */}
                            <div className="col-12 d-flex flex-wrap flex-nowrap justify-content-between overflow-auto">
                                {standardTipAmounts.map((amount) => (
                                    <button
                                        key={amount}
                                        className={`flex-grow-1 btn btn-sm ${!showCustomAmount && tipAmount === amount ? 'btn-info' : 'btn-outline-info'} me-2 mb-2`}
                                        onClick={() => handleTipAmountChange(amount)}
                                    >
                                        {localCurrency((amount/1000000000), preferences, exchangeRates)}
                                    </button>
                                ))}
                                <button className={`flex-grow-1 btn btn-sm ${showCustomAmount ? 'btn-info' : 'btn-outline-info'} mb-2`} onClick={handleCustomAmountClick}>Custom</button>
                            </div>
                            {/* Conditional rendering for custom amount inputs */}
                            {showCustomAmount && (
                                <>
                                    <div className="col-12">
                                        <p>Custom value:</p>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={customDesoAmount} onChange={(event) => handleCustomDesoAmountChange(event.target.value)} />
                                            <span className="input-group-text">DESO</span>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={customLocalCurrencyAmount} onChange={(event) => handleCustomLocalCurrencyAmountChange(event.target.value)} />
                                            <span className="input-group-text">{preferences.currency}</span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* Tip note input */}
                            <div className="col-12 mb-3">
                                <p>Would you like to leave a note?</p>
                                <textarea className="form-control" rows="4" onChange={handleTipNoteChange}></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                        <button className="btn btn-outline-secondary" onClick={toggleModal}>Cancel</button>
                        <button className="btn btn-primary" onClick={handleConfirmTip}>
                            {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
                        </button>
                    </div>
                </Modal>
            ) : (
                <Modal show={showModal} onHide={toggleModal} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
                    <Modal.Body>
                        <div className="mb-3 d-flex flex-row flex-nowrap justify-content-between">
                            <div className="d-flex flex-column">
                                <div className="fw-light d-flex flex-row flex-nowrap">
                                    <span className='pe-2 fw-bold'>
                                        <i
                                            ref={buttonRef}
                                            className={`bi bi-cash`}
                                            aria-label="Send Tip"
                                        ></i> Send Direct Tip
                                    </span>
                                </div>
                            </div>
                            <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={toggleModal}>
                                <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                Sorry, but you cannot send a tip to yourself
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                        <button className="btn btn-outline-secondary" onClick={toggleModal}>Cancel</button>
                    </div>
                </Modal>
            )}

            {/* confirmation modal */}
            <Modal show={tipSuccess} onHide={() => setTipSuccess(false)} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
                <Modal.Body>
                    <div className="mb-3 d-flex flex-row flex-nowrap justify-content-between">
                        <div className="d-flex flex-column">
                            <div className="fw-light d-flex flex-row flex-nowrap">
                                <span className='pe-2 fw-bold'>
                                    <i
                                        ref={buttonRef}
                                        className={`bi bi-cash`}
                                        aria-label="Send Tip"
                                    ></i> Tip Sent Successfully
                                </span>
                            </div>
                        </div>
                        <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={() => setTipSuccess(false)}>
                            <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Close
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-4 my-2">
                            Recipient
                        </div>
                        <div className="col-8 my-2">
                            <Avatar type="avatar" publicKey={Receiver.PublicKeyBase58Check} /> <Avatar type="username" publicKey={Receiver.PublicKeyBase58Check} />
                        </div>
                        <div className="col-4 my-2">
                            Value
                        </div>
                        <div className="col-8 my-2">
                            {showCustomAmount ? localCurrency((customDesoAmount), preferences, exchangeRates) : localCurrency((tipAmount), preferences, exchangeRates)}
                        </div>
                        <div className="col-4 my-2">
                            Tip Note
                        </div>
                        <div className="col-8 my-2">
                            {tipNote}
                        </div>
                    </div>
                    <button className="btn btn-success" onClick={() => setTipSuccess(false)}>Close</button>
                </Modal.Body>
            </Modal>

        </>
    );
};


/*******************************************************************
 * Create/Update Bookmark
 */

export const Bookmark = ({ post, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [numBookmarks, setNumBookmarks] = useState(0);
    const [refreshBookmark, setRefreshBookmark] = useState(true);
    const [existingBookmark, setExistingBookmark] = useState(null);

    const SenderPublicKeyBase58Check = currentUser.PublicKeyBase58Check;

    useEffect(() => {
        const checkBookmark = async () => {
            try {
                const checkBookmarkRequest = {
                    PostHashHex: post.PostHashHex,
                    TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    AssociationType: "BOOKMARK",
                    AssociationValue: "BOOKMARK",
                };
                const isBookmarkedResponse = await getPostAssociations(checkBookmarkRequest);
                if (isBookmarkedResponse && isBookmarkedResponse.Associations.length > 0) {
                    setIsBookmarked(true);
                    setExistingBookmark(isBookmarkedResponse.Associations[0]);
                } else {
                    setIsBookmarked(false);
                    setExistingBookmark(null);
                }
            } catch (error) {
                console.error("Error checking bookmark:", error);
            }
        };

        const checkAllBookmarks = async () => {
            try {
                const checkAllBookmarksRequest = {
                    PostHashHex: post.PostHashHex,
                    AssociationType: "BOOKMARK",
                    AssociationValue: "BOOKMARK",
                };
                const countBookmarks = await countPostAssociations(checkAllBookmarksRequest);
                if (countBookmarks && countBookmarks.Counts.BOOKMARK > 0) {
                    setNumBookmarks(countBookmarks.Counts.BOOKMARK);
                } else {
                    setNumBookmarks(0);
                }
            } catch (error) {
                console.error("Error checking all bookmarks:", error);
            }
        };

        if (refreshBookmark) {
            checkBookmark();
            checkAllBookmarks();
            setRefreshBookmark(false);
        }
    }, [post, currentUser, refreshBookmark]);

    const handleBookmarkClick = () => {
        setShowModal(true);
    };

    const handleUpdateBookmark = async (post, SenderPublicKeyBase58Check, note, deleteBookmark, existingBookmark, setRefreshBookmark) => {
        try {
            await updateBookmark({
                post,
                SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                note,
                deleteBookmark,
                existingBookmark,
                setRefreshBookmark
            });
            setRefreshBookmark(true);
            setShowModal(false);
        } catch (error) {
            console.error("Error updating bookmark:", error);
        }
    };

    return (
        <>
            <span className="btn m-0 px-2 action" title={`${numBookmarks > 0 ? `${numBookmarks} users have bookmarked this` : `Not yet bookmarked`}`} onClick={handleBookmarkClick}>
                {numBookmarks > 0 ? numBookmarks : null} <i className={`bi ${isBookmarked ? 'bi-bookmark-star-fill text-warning' : 'bi-bookmark-star'}`}></i>
            </span>
            {showModal && (
                <BookmarkModal
                    onClose={() => setShowModal(false)}
                    isBookmarked={isBookmarked}
                    post={post}
                    currentUser={currentUser}
                    updateBookmark={handleUpdateBookmark}
                    existingBookmark={existingBookmark}
                    setRefreshBookmark={setRefreshBookmark}
                />
            )}
        </>
    );
};

/*
export const Bookmark = ({ post, currentUser }) => {
    const [showModal, setShowModal] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [numBookmarks, setNumBookmarks] = useState(0);
    const [refreshBookmark, setRefreshBookmark] = useState(true);
    const [existingBookmark, setExistingBookmark] = useState(null);

    const SenderPublicKeyBase58Check = currentUser.PublicKeyBase58Check;

    useEffect(() => {
        const checkBookmark = async () => {
            const checkBookmarkRequest = {
                PostHashHex: post.PostHashHex,
                TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                AssociationType: "BOOKMARK",
                AssociationValue: "BOOKMARK",
            };
            const isBookmarked = await getPostAssociations(checkBookmarkRequest);
            if (isBookmarked && isBookmarked.Associations.length > 0) {
                setIsBookmarked(true);
                setExistingBookmark(isBookmarked.Associations[0]);
            } else {
                setIsBookmarked(false);
                setExistingBookmark(null);
            }
        };

        const checkAllBookmarks = async () => {
            const checkAllBookmarksRequest = {
                PostHashHex: post.PostHashHex,
                //TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                AssociationType: "BOOKMARK",
                AssociationValue: "BOOKMARK",
            };
            const allBookmarks = await getPostAssociations(checkAllBookmarksRequest);
            //console.log("[BOOKMARKS] ALL bookmarks:", allBookmarks);
            if (allBookmarks && allBookmarks.Associations.length > 0) {
                setNumBookmarks(allBookmarks.Associations.length);
            } else {
                setNumBookmarks(0);
            }
        };

        checkBookmark();
        checkAllBookmarks();
        setRefreshBookmark(false);
    }, [post, currentUser, refreshBookmark]);

    const handleBookmarkClick = () => {
        setShowModal(true);
    };

    // Function to handle update bookmark
    const handleUpdateBookmark = async (post, SenderPublicKeyBase58Check, note, deleteBookmark, existingBookmark, setRefreshBookmark) => {
        try {
            await updateBookmark({ 
                post, 
                SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                note,
                deleteBookmark,
                existingBookmark,
                setRefreshBookmark
            });
            setRefreshBookmark(true);
            setShowModal(false);
        } catch (error) {
            // Handle error
            console.error("Error updating bookmark:", error);
        }
    };

    return (
        <>
            <button className="btn m-0 px-2" title={`${numBookmarks > 0 ? `${numBookmarks} users have bookmarked this post` : `Post has not been bookmarked yet` }`} onClick={handleBookmarkClick}>
                {numBookmarks > 0 ? numBookmarks : null} <i className={`bi ${isBookmarked ? 'bi-bookmark-star-fill text-warning' : 'bi-bookmark-star' }`}></i>
            </button>
            {showModal && (
                <BookmarkModal 
                    onClose={() => setShowModal(false)} 
                    isBookmarked={isBookmarked} 
                    post={post} 
                    currentUser={currentUser} 
                    updateBookmark={handleUpdateBookmark}
                    existingBookmark={existingBookmark}
                    setRefreshBookmark={setRefreshBookmark}
                />
            )}
        </>
    );
};
*/
// Existing code for BookmarkModal component
const BookmarkModal = ({ onClose, isBookmarked, post, currentUser, updateBookmark, existingBookmark, setRefreshBookmark }) => {
    const [note, setNote] = useState(existingBookmark?.ExtraData?.note || '');
    const [action, setAction] = useState(isBookmarked ? "update" : "create");

    //console.log("[BOOKMARKS] data:",onClose, isBookmarked, post, currentUser, updateBookmark, existingBookmark);

    const handleConfirm = async (deleteBookmark) => {
        try {
            await updateBookmark(post, currentUser.PublicKeyBase58Check, note, deleteBookmark, existingBookmark, setRefreshBookmark);
            onClose(); // Close modal after updating
            return 'success';
        } catch (error) {
            // Handle error
            console.error("Error confirming action:", error);
        }
    };

    //console.log("[BOOKMARKS] post~:",post);
    return (
        <Modal show={true} onHide={onClose} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
            <Modal.Body>
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <h5 className="modal-title"><i className={`bi bi-bookmark-star-fill me-2`}></i>Bookmark Post</h5>
                    <button type="button" className="btn-close" onClick={onClose}></button>
                </div>
                <div className="d-flex flex-row mb-3">
                    <div><Avatar type="avatar" publicKey={post.PosterPublicKeyBase58Check} /></div>
                    <div><Avatar publicKey={post.PosterPublicKeyBase58Check} /></div>
                    <div className="flex-grow-1 text-end">{friendlyFormatDate(post.TimestampNanos/1000000)}</div>
                </div>
                <div className="col-12 mb-3"><strong>Snippet:</strong> {post.Body}</div>
                <div className="mb-3">
                    <label htmlFor="note" className="form-label">Add Note:</label>
                    <input type="text" className="form-control" id="note" value={note} onChange={(e) => setNote(e.target.value)} />
                </div>
            </Modal.Body>
            <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                {isBookmarked && (
                    <button 
                        type="button" 
                        className="btn btn-danger" 
                        onClick={() => {
                            handleConfirm(true);
                        }}
                    >
                        Delete Bookmark
                    </button>
                )}
                <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => {
                        handleConfirm(false);
                    }}>
                    {action === "delete" ? "Confirm Delete" : existingBookmark ? "Update Bookmark" : "Create Bookmark"}
                </button>
            </div>
        </Modal>

        
    );
};

export const updateBookmark = async ({ post, SenderPublicKeyBase58Check, note, deleteBookmark, existingBookmark, setRefreshBookmark }) => {
    //console.log("[BOOKMARKS] updateBookmark()..."); //, post, SenderPublicKeyBase58Check, note, deleteBookmark, existingBookmark);
    //console.log("[BOOKMARKS] post:", post);
    //console.log("[BOOKMARKS] SenderPublicKeyBase58Check:", SenderPublicKeyBase58Check);
    //console.log("[BOOKMARKS] note:", note);
    //console.log("[BOOKMARKS] deleteBookmark:", deleteBookmark);
    //console.log("[BOOKMARKS] existingBookmark:", existingBookmark);
    
    const handleUpdateBookmark = async () => {
        //console.log("[BOOKMARKS] handleUpdateBookmark...", post, SenderPublicKeyBase58Check, note, deleteBookmark, existingBookmark);

        if (!SenderPublicKeyBase58Check || !post) {
            return;
        }

        try {

            if (deleteBookmark && existingBookmark) {
                //console.log("[BOOKMARKS] DELETE existing:", existingBookmark);
                // We're deleting it
                const deleteRequest = {
                    AssociationID: existingBookmark.AssociationID,
                    TransactorPublicKeyBase58Check: SenderPublicKeyBase58Check,
                };
                //console.log("[BOOKMARKS] Deleting Bookmark:", deleteRequest);
                const response = await deletePostAssociation(deleteRequest);
                //console.log("[BOOKMARKS] Response:", response);
            } else if (!deleteBookmark && !existingBookmark) {
                //console.log("[BOOKMARKS] Create NEW Bookmark:");
                // We're creating a new bookmark
                const request = {
                    TransactorPublicKeyBase58Check: SenderPublicKeyBase58Check,
                    PostHashHex: post.PostHashHex,
                    AssociationType: "BOOKMARK",
                    AssociationValue: "BOOKMARK",
                    ExtraData: {
                        TimestampNanos: (Date.now() * 1e6).toString(),
                    },
                };
                if(note && note) { request.ExtraData.note = note; }

                //console.log("[BOOKMARKS] Creating Bookmark:", request);
                const response = await createPostAssociation(request);
                //console.log("[BOOKMARKS] Response:", response);
                if(response && response.submittedTransactionResponse) {
                    return 'success';
                }
            } else if (!deleteBookmark && existingBookmark) {
                //console.log("[BOOKMARKS] Updating Bookmark:", existingBookmark);
                // We're updating a bookmark (maybe updating the note)
                // You can decide what updating a bookmark means in your context
                const request = {
                    AssociationID: existingBookmark.AssociationID,
                    TransactorPublicKeyBase58Check: SenderPublicKeyBase58Check,
                    PostHashHex: post.PostHashHex,
                    AssociationType: "BOOKMARK",
                    AssociationValue: "BOOKMARK",
                    ExtraData: {
                        TimestampNanos: (Date.now() * 1e6).toString(),
                    },
                };
                if(note && note) { request.ExtraData.note = note; }

                //console.log("[BOOKMARKS] Update Bookmark Request:", request);
                const response = await createPostAssociation(request);
                //console.log("[BOOKMARKS] Update Bookmark Response:", response);
            }
        } catch (error) {
            //handleError(error.message); // Handle error using the handleError function
        } finally {
            setRefreshBookmark(true);
        }
    };
    handleUpdateBookmark();
};

export const ShootingStars = ({ trigger }) => {
    if(trigger) {
        return (
            <div className={`sky`} style={{ zIndex: "30000" }}>
              {Array.from({ length: 50 }).map((_, index) => (
                <div key={index} className={`star star-${index + 1}`}></div>
              ))}
            </div>
          );
    }
};


export const handlePinPost = async (post, currentUser, preferences) => {
    const userLocale = navigator.language || navigator.userLanguage;
    try {
        //console.log("[posts.js] pinPost:", post, currentUser);

        // Define your payload structure
        const newValue = post.IsPinned === false ? true : false;
        post.PostExtraData["IsPinned"] = newValue ? "true" : "false";
        const payload = {
            UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            PostHashHexToModify: post.PostHashHex,
            ParentStakeID: post.ParentStakeID,
            BodyObj: {
                Body: post.Body, // Placeholder for body content
                ImageURLs: post.ImageURLs, // Placeholder for image URLs
                VideoURLs: post.VideoURLs, // Placeholder for video URLs
            },
            isPinned: newValue, // Toggle the pin status
            IsHidden: post.IsHidden, // Toggle the pin status
            RepostedPostHashHex: post.RecloutedPostEntryResponse ? post.RecloutedPostEntryResponse.PostHashHex : null,
            PostExtraData: post.PostExtraData,
            TransactionFees: [localFees()], // Placeholder for transaction fees
        };

        //console.log("[posts.js] pinPost - submit post:", payload);
        const response = await submitPost(payload);
        //console.log("[posts.js] pinPost - submit response:", response);
        // Check if the request was successful
        if (!response.ok) {
            throw new Error('Failed to pin post');
        }

        // If successful, parse the response JSON
        const data = await response.json();

        // Handle the result if needed
        //console.log('Post pinned:', data);

        // Additional logic after pinning the post, if needed
    } catch (error) {
        // Handle any errors
        //console.error('Error pinning post:', error);
    }
};

/***************************************************
 * Hide Post 
 */

export function HidePost({ showHideModal, setShowHideModal, currentUser, post }) {
    const { addPostToRefresh } = useContext(AppDataContext);
 
    const handleHidePost = () => {
      const payload = {
        PosterPublicKey: currentUser.PublicKeyBase58Check,
        PostHashHex: post.PostHashHex,
        IsHidden: post.IsHidden ? false : true,

        UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
        PostHashHexToModify: post.PostHashHex,
        ParentStakeID: post.ParentStakeID || null,
        BodyObj: {
            Body: post.Body || null,
            ImageURLs: post.ImageURLs || null,
            VideoURLs: post.VideoURLs || null,
        },
        RepostedPostHashHex: post.RepostedPostHashHex || null,
        PostExtraData: post.PostExtraData || null,
        TransactionFees: [ localFees() ],
      };
      
      // Submit the post hide request
      submitPost(payload);
  
      // Close modal after submission
      addPostToRefresh(post.PostHashHex);
      setShowHideModal(false);
    };
  
    return (
      <>
        <Modal show={showHideModal} onHide={() => setShowHideModal(false)} centered={true} fullscreen="sm-down" size="md" className="modal-autoheight">
          <Modal.Body>
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <h5 className="modal-title"><i className={`bi bi-eye-slash me-2`}></i>Confirm {post.IsHidden ? `Unhide` : `Hide`} Post</h5>
                    <button type="button" className="btn-close" onClick={() => setShowHideModal(false)}></button>
                </div>
                <div className="d-flex flex-column text-center pt-3 mb-3">
                    <p className="fw-bold">
                        Are you sure you want to {post.IsHidden ? `unhide` : `hide`} this post?
                    </p>
                    <p>
                        <strong>Important:</strong> please note no data can be deleted from the blockchain - hiding content only marks it as hidden.
                    </p>
                </div>
            </Modal.Body>
            <div className="btn-group d-flex flex-row flex-nowrap justify-content-between">
                <Button variant="secondary" onClick={() => setShowHideModal(false)}>
                Cancel
                </Button>
                <Button variant="danger" onClick={handleHidePost}>
                Confirm {post.IsHidden ? `Unhide` : `Hide`}
                </Button>
            </div>
        </Modal>
      </>
    );
  }