import { configure, createPostAssociation, createUserAssociation, deleteUserAssociation, getUserAssociations, submitPost } from "deso-protocol";
import React, { useState, useEffect, useContext } from 'react';
import { DeSoIdentityContext } from "react-deso-protocol";
import { identity } from "deso-protocol";
import { useUserPreferences } from "../contexts/UserPreferences";
import { Spinner } from "react-bootstrap";

export const systemKey = process.env.REACT_APP_SYSTEM_KEY;
export const appName = process.env.REACT_APP_APPNAME;
const betaUsersString = process.env.REACT_APP_BETAUSERS || '';
const betaUsers = betaUsersString
  .split(',')
  .map(user => user.trim())  // remove any extra spaces
  .filter(user => user !== '');

const localPreferences = localStorage.getItem('preferences');
const desoActivePublicKey = localStorage.getItem('desoActivePublicKey');
let nodeURI = 'https://node.deso.org';
if (localPreferences) {
  const preferences = JSON.parse(localPreferences);
  if (desoActivePublicKey && preferences[desoActivePublicKey]) {
    nodeURI = preferences[desoActivePublicKey].nodeURI;
  } else {
    // Get the first available nodeURI from preferences
    const firstKey = Object.keys(preferences)[0];
    if (firstKey && preferences[firstKey].nodeURI) {
      nodeURI = preferences[firstKey].nodeURI;
    }
  }
}

export const BetaLogin = () => {
    useEffect(() => {
        configure({
          spendingLimitOptions: {
            "GlobalDESOLimit": 1000000000,
            "TransactionCountLimitMap": {
              "UPDATE_PROFILE": 1000000000,
              "CREATE_NFT": 1000000000,
              "UPDATE_NFT": 1000000000,
              "SUBMIT_POST": 1000000000,
              "NEW_MESSAGE": 1000000000,
              "BASIC_TRANSFER": 1000000000,
              "FOLLOW": 1000000000,
              "LIKE": 1000000000,
              "CREATOR_COIN": 1000000000,
              "CREATOR_COIN_TRANSFER": 1000000000,
              "ACCEPT_NFT_BID": 1000000000,
              "BURN_NFT": 1000000000,
              "CREATE_USER_ASSOCIATION": 1000000000,
              "CREATE_POST_ASSOCIATION": 1000000000,
              "ACCESS_GROUP": 1000000000,
              "ACCESS_GROUP_MEMBERS": 1000000000,
              "AUTHORIZE_DERIVED_KEY": 1
            },
            "CreatorCoinOperationLimitMap": {
              "": {
                "any": 1000000000
              }
            },
            "AssociationLimitMap": [
              {
                "AssociationClass": "Post",
                "AssociationType": "",
                "AppScopeType": "Any",
                "AppPublicKeyBase58Check": "",
                "AssociationOperation": "Any",
                "OpCount": 1000000000
              },
              {
                "AssociationClass": "User",
                "AssociationType": "",
                "AppPublicKeyBase58Check": "",
                "AppScopeType": "Any",
                "AssociationOperation": "Any",
                "OpCount": 1000000000
              }
            ],
            "AccessGroupLimitMap": [
              {
                "AccessGroupOwnerPublicKeyBase58Check": "",
                "ScopeType": "Any",
                "AccessGroupKeyName": "",
                "OperationType": "Any",
                "OpCount": 1000000000
              }
            ],
            "AccessGroupMemberLimitMap": [
              {
                "AccessGroupOwnerPublicKeyBase58Check": "",
                "ScopeType": "Any",
                "AccessGroupKeyName": "",
                "OperationType": "Any",
                "OpCount": 1000000000
              }
            ],
            "NFTOperationLimitMap": {
              "": {
                "0": {
                  "any": 1000000000
                }
              }
            }
          },
          appName: appName,
          showSkip: true,
          nodeURI: nodeURI,
        });
      }, []);

    return (
            <button
                className="btn btn-primary m-1"
                onClick={() => identity.login()}
            >
                <i className="bi bi-key-fill"></i> Login with DESO identity
            </button>
    )
}

export const BetaExpressInterest = () => {
    const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
    const [expressedInterest, setExpressedInterest] = useState(false);
    const [requested, setRequested] = useState(false);
    const [associationID, setAssociationID] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        configure({
          spendingLimitOptions: {
            "GlobalDESOLimit": 1000000000,
            "TransactionCountLimitMap": {
              "UPDATE_PROFILE": 1000000000,
              "CREATE_NFT": 1000000000,
              "UPDATE_NFT": 1000000000,
              "SUBMIT_POST": 1000000000,
              "NEW_MESSAGE": 1000000000,
              "BASIC_TRANSFER": 1000000000,
              "FOLLOW": 1000000000,
              "LIKE": 1000000000,
              "CREATOR_COIN": 1000000000,
              "CREATOR_COIN_TRANSFER": 1000000000,
              "ACCEPT_NFT_BID": 1000000000,
              "BURN_NFT": 1000000000,
              "CREATE_USER_ASSOCIATION": 1000000000,
              "CREATE_POST_ASSOCIATION": 1000000000,
              "ACCESS_GROUP": 1000000000,
              "ACCESS_GROUP_MEMBERS": 1000000000,
              "AUTHORIZE_DERIVED_KEY": 1
            },
            "CreatorCoinOperationLimitMap": {
              "": {
                "any": 1000000000
              }
            },
            "AssociationLimitMap": [
              {
                "AssociationClass": "Post",
                "AssociationType": "",
                "AppScopeType": "Any",
                "AppPublicKeyBase58Check": "",
                "AssociationOperation": "Any",
                "OpCount": 1000000000
              },
              {
                "AssociationClass": "User",
                "AssociationType": "",
                "AppPublicKeyBase58Check": "",
                "AppScopeType": "Any",
                "AssociationOperation": "Any",
                "OpCount": 1000000000
              }
            ],
            "AccessGroupLimitMap": [
              {
                "AccessGroupOwnerPublicKeyBase58Check": "",
                "ScopeType": "Any",
                "AccessGroupKeyName": "",
                "OperationType": "Any",
                "OpCount": 1000000000
              }
            ],
            "AccessGroupMemberLimitMap": [
              {
                "AccessGroupOwnerPublicKeyBase58Check": "",
                "ScopeType": "Any",
                "AccessGroupKeyName": "",
                "OperationType": "Any",
                "OpCount": 1000000000
              }
            ],
            "NFTOperationLimitMap": {
              "": {
                "0": {
                  "any": 1000000000
                }
              }
            }
          },
          appName: appName,
          showSkip: true,
        });
      }, [currentUser]);
  
    useEffect(() => {
      const checkForAssociation = async () => {
        try {
          const response = await getUserAssociations({
            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            TargetUserPublicKeyBase58Check: systemKey,
            AssociationType: 'EOI-beta',
          });
  
          if (response && response.Associations.length > 0) {
            setExpressedInterest(true);
            setAssociationID(response.Associations[0].AssociationID);
            setRequested(false);
          } 
        } catch (error) {
          setError("An error occurred while fetching associations:", error);
          setRequested(false);
        }
      };
      checkForAssociation();
    }, [currentUser, expressedInterest, requested]);
  
    const handleExpressInterest = async () => {
      setRequested(true);
      try {
        const response = await createUserAssociation({
          TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          TargetUserPublicKeyBase58Check: systemKey,
          AppPublicKeyBase58Check: systemKey,
          AssociationType: 'EOI-beta',
          AssociationValue: 'Expression of interest for news and beta testing',
        });
  
        if (response && response?.submittedTransactionResponse?.TxnHashHex) {
          setExpressedInterest(true);
          setAssociationID(response.submittedTransactionResponse.TxnHashHex);
        } else {
          console.error("Failed to record expression of interest.");
          setRequested(false);
        }
      } catch (error) {
        setError("An error occurred while expressing interest:", error);
        setRequested(false);
      }
    };
  
    const handleRemoveExpressInterest = async () => {
      setRequested(true);
      try {
        const response = await deleteUserAssociation({
          TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          AssociationID: associationID,
        });
  
        //console.log(response);
  
        if (response && response.submittedTransactionResponse) {
          setExpressedInterest(false);
          setAssociationID(null);
        } else {
            setError("Failed to remove expression of interest.");
        }
      } catch (error) {
        setError("An error occurred while removing expression of interest:", error);
      } finally {
        setRequested(false);
      }
    };
  
    return (
      <div className="card">
          {expressedInterest && associationID ? (
            <>
                <div className="card-header bg-success">
                    <span className="fw-bold">
                        <i className="bi bi-check-circle-fill me-3"></i>
                        Your expression of interest has been recorded
                    </span>
                </div>
                <div className="card-body">
                    <button
                        className="btn btn-outline-secondary btn-sm mt-3"
                        onClick={handleRemoveExpressInterest}
                        disabled={requested}
                    >
                        {requested ? (
                        <>
                            <Spinner />
                        </>
                        ) : (
                        <>
                            <i className="bi bi-x-circle-fill me-2"></i>
                            Remove your expression of interest
                        </>
                        )}
                    </button>
                </div>
            </>
          ) : (
            <div className="card-body">
              <h4>Express interest</h4>
              <p>Request an invite and receive updates about the project...</p>
              <button
                className="btn btn-success mb-3"
                onClick={handleExpressInterest}
                disabled={requested}
              >
                {requested ? (
                  <>
                    <Spinner />
                  </>
                ) : (
                  <>
                    <i className="bi bi-postcard-fill me-2"></i>
                    Express your interest
                  </>
                )}
              </button>
            </div>
          )}
          {error && (
            <div className="card-footer text-center">
                <i className="bi bi-exclamation-circle-fill fs-1"></i>
                <p>{error}</p>
            </div>
          )}
      </div>
    );
  };
  