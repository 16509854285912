import React, { createContext, useState, useEffect, useContext } from 'react';
import { DeSoIdentityContext } from "react-deso-protocol";
import { checkPartyAccessGroups, createAccessGroup, getAllAccessGroups, getAllMessageThreads, getPaginatedAccessGroupMembers, identity } from 'deso-protocol';
import { addProfilesToCache } from '../services/profileCache';

export const AccessGroupsContext = createContext();

export const AccessGroupsProvider = ({ children }) => {
  const { currentUser } = useContext(DeSoIdentityContext);
  const [accessGroups, setAccessGroups] = useState(null);
  const [chatThreads, setChatThreads] = useState([]); // Initialize as an array
  const [isLoading, setIsLoading] = useState(true);
  const [showModalChat, setShowModalChat] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [accessGroupMembers, setAccessGroupMembers] = useState({});

  const triggerRefreshAccessGroups = () => {
    fetchAccessGroups();
  }

  const fetchAccessGroups = async () => {
    try {
      if (currentUser) {
        const groups = await getAllAccessGroups({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check });
        setAccessGroups(groups);

        if (!groups || !groups.AccessGroupsOwned.some(group => group.AccessGroupKeyName === "default-key")) {
          console.log("[AccessGroups.js] fetchAccessGroups, no default-key found:", currentUser, groups);
          console.log("[AccessGroups.js] WE NEED TO CONFIGURE USER DEFAULT ACCESS KEY");
          const defaultKey = await identity.accessGroupStandardDerivation("default-key");
          console.log("[AccessGroups.js] fetchAccessGroups, defaultKey:", defaultKey);
          const newDefaultKey = await createAccessGroup({
            AccessGroupOwnerPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            AccessGroupKeyName: defaultKey.AccessGroupKeyName,
            AccessGroupPublicKeyBase58Check: defaultKey.AccessGroupPublicKeyBase58Check,
          });
          console.log("[AccessGroups.js] fetchAccessGroups, newDefaultKey:", newDefaultKey);
          // Refetch access groups after creating the default key
          fetchAccessGroups();
          return;
        }

        fetchChatData();
      } else {
        setAccessGroups(null);
      }
    } catch (error) {
      console.error('[AccessGroups.jsx] Error fetching access groups:', error);
    }
  };

  const fetchChatData = async () => {
    if (!currentUser) return;

    try {
      const messageThreads = await getAllMessageThreads({
        UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      });
      setChatThreads(messageThreads || []);
      addProfilesToCache(messageThreads.PublicKeyToProfileEntryResponse);
      setIsInitialLoad(false);
      setIsLoading(false);
    } catch (error) {
      console.error('[AccessGroups.jsx] Error fetching chat data:', error);
    }
  };

  const fetchAccessGroupMembers = async () => {
    if (!currentUser || !accessGroups) return;

    try {
      console.log("[AccessGroups.js] fetchAccessGroupMembers, accessGroups:",accessGroups);
      accessGroups.Members = [];
      if (accessGroups.AccessGroupsMember) {
        for (const group of accessGroups.AccessGroupsMember) {
          const paginatedMembers = await getPaginatedAccessGroupMembers({
            AccessGroupOwnerPublicKeyBase58Check: group.AccessGroupOwnerPublicKeyBase58Check,
            AccessGroupKeyName: group.AccessGroupKeyName,
            MaxMembersToFetch: 200,
          });
          addProfilesToCache(paginatedMembers.PublicKeyToProfileEntryResponse);
          accessGroups.Members[group.AccessGroupKeyName] = paginatedMembers.AccessGroupMembersBase58Check;
        }
      }
      //setAccessGroupMembers(newMembers);
    } catch (error) {
      console.error('[AccessGroups.jsx] Error fetching access group members:', error);
    }
  };

  useEffect(() => {
    setAccessGroups(null);
    setChatThreads([]);
    fetchAccessGroups();
  }, [currentUser]);

  useEffect(() => {
    fetchAccessGroupMembers();
  }, [accessGroups]);

  useEffect(() => {
    if (currentUser && accessGroups) {
      // Poll chat data frequently
      const chatPollingInterval = setInterval(fetchChatData, 25000);

      // Poll access group members less frequently
      const membersPollingInterval = setInterval(fetchAccessGroups, 300000);

      return () => {
        clearInterval(chatPollingInterval);
        clearInterval(membersPollingInterval);
      };
    }
  }, [currentUser, accessGroups]);

  const initiateChat = async (ReceiverPublicKeyBase58Check, isGroupChat = false) => {
    // Wait until chatThreads are loaded if it's the initial load
    if (isInitialLoad) {
      await new Promise(resolve => {
        const checkInterval = setInterval(() => {
          if (!isInitialLoad) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100); // Check every 100ms
      });
    }
  
    let existingThread;
    let accessGroupKeyName = 'default-key';
    let accessGroupDetails;
  
    if (isGroupChat) {
      // For GroupChat, find the thread by AccessGroupPublicKeyBase58Check
      existingThread = chatThreads?.MessageThreads?.find(thread =>
        thread.ChatType === 'GroupChat' &&
        thread.RecipientInfo.AccessGroupPublicKeyBase58Check === ReceiverPublicKeyBase58Check
      );
      console.log(accessGroups);
      accessGroupDetails = getAccessGroup(ReceiverPublicKeyBase58Check,accessGroups);
      accessGroupKeyName = accessGroupDetails.AccessGroupKeyName;
      console.log(accessGroupKeyName);
      console.log("Existing thread?",existingThread);
    } else {
      // For DM, find the thread by checking Sender and Recipient Public Keys
      existingThread = chatThreads?.MessageThreads?.find(thread => 
        thread.ChatType === 'DM' &&
        (thread.SenderInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check ||
        thread.RecipientInfo.OwnerPublicKeyBase58Check === ReceiverPublicKeyBase58Check)
      );
    }
  
    if (existingThread) {
      setSelectedThread(existingThread);
    } else {
      const request = {
        "SenderAccessGroupKeyName": "default-key",
        "SenderPublicKeyBase58Check": currentUser.PublicKeyBase58Check,
        "RecipientAccessGroupKeyName": isGroupChat ? accessGroupKeyName : "default-key",
        "RecipientPublicKeyBase58Check": isGroupChat ? accessGroupDetails.AccessGroupOwnerPublicKeyBase58Check : ReceiverPublicKeyBase58Check,
      };
  
      try {
        const partyAccessGroups = await checkPartyAccessGroups(request);
        console.log("[AccessGroups.js] request & partyAccessGroups:",request,partyAccessGroups);
        const newThread = {
          "ChatType": isGroupChat ? 'GroupChat' : 'DM', // Set ChatType based on isGroupChat
          "SenderInfo": {
            "OwnerPublicKeyBase58Check": partyAccessGroups.SenderPublicKeyBase58Check,
            "AccessGroupPublicKeyBase58Check": partyAccessGroups.SenderAccessGroupPublicKeyBase58Check,
            "AccessGroupKeyName": partyAccessGroups.SenderAccessGroupKeyName,
          },
          "RecipientInfo": isGroupChat
            ? { // GroupChat has AccessGroupPublicKeyBase58Check
                "AccessGroupPublicKeyBase58Check": partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
                "AccessGroupKeyName": partyAccessGroups.RecipientAccessGroupKeyName,
                "OwnerPublicKeyBase58Check": partyAccessGroups.RecipientPublicKeyBase58Check,
              }
            : { // DM doesn't use AccessGroupPublicKeyBase58Check
                "OwnerPublicKeyBase58Check": partyAccessGroups.RecipientPublicKeyBase58Check,
                "AccessGroupPublicKeyBase58Check": partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
                "AccessGroupKeyName": partyAccessGroups.RecipientAccessGroupKeyName,
              },
          "NewThread": true,
        };
        setChatThreads(prevThreads => ({
          ...prevThreads,
          MessageThreads: [...(prevThreads.MessageThreads || []), newThread]
        }));
        setSelectedThread(newThread);
      } catch (error) {
        console.error("Error checking party access groups:", error);
      }
    }
  
    setShowModalChat(true);
  };
  

  return (
    <AccessGroupsContext.Provider value={{ accessGroups, chatThreads, isLoading, showModalChat, setShowModalChat, initiateChat, selectedThread, setSelectedThread, fetchChatData, triggerRefreshAccessGroups }}>
      {children}
    </AccessGroupsContext.Provider>
  );
};

const getAccessGroup = (accessGroupPublicKeyBase58Check, accessGroups) => {
  // Destructure the arrays from accessGroups
  const { AccessGroupsMember = [], AccessGroupsOwned = [] } = accessGroups;

  // Combine both arrays for a single search
  const allAccessGroups = [...AccessGroupsMember, ...AccessGroupsOwned];

  // Find the group that matches the given AccessGroupPublicKeyBase58Check
  const matchingGroup = allAccessGroups.find(
    (group) => group.AccessGroupPublicKeyBase58Check === accessGroupPublicKeyBase58Check
  );

  // Return the AccessGroupKeyName or null if not found
  return matchingGroup ? matchingGroup : null;
};