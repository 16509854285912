import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../utils/helpers.js';
import { localCurrency } from '../utils/currency.js';
import ExchangeRatesContext from '../contexts/ExchangeRatesContext.js';
import { useUserPreferences } from "../contexts/UserPreferences";
import { Spinner } from 'react-bootstrap';
import { deso_graphql } from '../utils/graphql.js';
import { Avatar } from '../utils/layouts.js';

const ProfileCoin = ({ profile, additionalData, setAdditionalData }) => {
    const [activeTab, setActiveTab] = useState('coinHolders');
    const { preferences } = useUserPreferences();
    const { exchangeRates } = useContext(ExchangeRatesContext);
    //console.log("<ProfileCoin> ProfileCoin pref:",preferences,exchangeRates);
    
    const calculateSellValue = (nodes) => {
        return nodes.reduce((total, node) => {
            const sellValue = parseFloat(node.txnMeta.CreatorCoinToSellNanos);
            return total + (isNaN(sellValue) ? 0 : sellValue);
        }, 0);
    };

    const calculateBuyValue = (nodes) => {
        return nodes.reduce((total, node) => {
            const sellValue = parseFloat(node.txnMeta.DeSoToSellNanos);
            return total + (isNaN(sellValue) ? 0 : sellValue);
        }, 0);
    };

    const calculateNetCCDifference = (nodes) => {
        return nodes.reduce((netDifference, node) => {
            const increase = parseFloat(node.txnMeta.MinCreatorCoinExpectedNanos);
            const decrease = parseFloat(node.txnMeta.CreatorCoinToSellNanos);
    
            // Ensure values are numbers and default to 0 if NaN
            const validIncrease = isNaN(increase) ? 0 : increase;
            const validDecrease = isNaN(decrease) ? 0 : decrease;
    
            return netDifference + validIncrease - validDecrease;
        }, 0);
    };

    useEffect(() => {
        const getSelfSells = async () => {
            try {
                const variables = {
                        "condition": {
                          "txnType": 11
                          
                        },
                        "first": 100,
                        "filter": {
                          "txIndexMetadata": {
                            "contains": {
                              "OperationType": "sell"
                            }
                          },
                          "publicKey": {
                            "equalTo": profile.PublicKeyBase58Check,
                          },
                          "affectedPublicKeys": {
                            "every": {
                              "publicKey": {
                                "equalTo": profile.PublicKeyBase58Check,
                              }
                            }
                          }
                        }
                    };
                const request = {
                    query: PROFILE_COIN_SELLS_QUERY,
                    variables: variables,
                    operationName: "Transactions"
                };
                const selfSells = await deso_graphql(request);
                //console.log("<ProfileCoin> selfSells:", selfSells);

                const coinSells = {
                    totalCount: selfSells.data.transactions.totalCount,
                    totalValue: calculateSellValue(selfSells.data.transactions.nodes),
                    transactions: selfSells.data.transactions.nodes,
                    coins: calculateNetCCDifference(selfSells.data.transactions.nodes),
                }
                
                setAdditionalData(prevData => ({
                    ...prevData,
                    coinSells: coinSells,
                }));
            } catch (error) {
                console.log("<ProfileCoin> Error fetching profile stats:", error);
            }
        };

        if (additionalData && !additionalData.coinSells) {
            getSelfSells();
        }
    }, [additionalData]);

    useEffect(() => {
        const getSelfBuys = async () => {
            try {
                const variables = {
                        "condition": {
                          "txnType": 11
                          
                        },
                        "first": 100,
                        "filter": {
                          "txIndexMetadata": {
                            "contains": {
                              "OperationType": "buy"
                            }
                          },
                          "publicKey": {
                            "equalTo": profile.PublicKeyBase58Check,
                          },
                          "affectedPublicKeys": {
                            "every": {
                              "publicKey": {
                                "equalTo": profile.PublicKeyBase58Check,
                              }
                            }
                          }
                        }
                    };
                const request = {
                    query: PROFILE_COIN_SELLS_QUERY,
                    variables: variables,
                    operationName: "Transactions"
                };
                const selfBuys = await deso_graphql(request);
                //console.log("<ProfileCoin> selfSells:", selfBuys);

                const coinBuys = {
                    totalCount: selfBuys.data.transactions.totalCount,
                    totalValue: calculateBuyValue(selfBuys.data.transactions.nodes),
                    transactions: selfBuys.data.transactions.nodes,
                    coins: calculateNetCCDifference(selfBuys.data.transactions.nodes),
                }
                
                setAdditionalData(prevData => ({
                    ...prevData,
                    coinBuys: coinBuys,
                }));
            } catch (error) {
                console.log("<ProfileCoin> Error fetching profile stats:", error);
            }
        };

        if (additionalData && !additionalData.coinBuys) {
            getSelfBuys();
        }
    }, [additionalData]);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    if (!additionalData) {
        // Display loader or any other loading indicator
        return <Loader />;
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className="card shadow-sm h-100 text-center action" onClick={() => setActiveTab('creatorcoin')}>
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-coin me-3`}></i>Coin Value</h5>
                        </div>
                        <div className="card-body">
                            {additionalData && additionalData.coinPriceDesoNanos !== undefined ? (
                                <div className='row h-100 d-flex align-items-center'>
                                    <div className='col-12 align-items-center'>
                                        <div>
                                            <span className='fs-3'>{(additionalData.coinPriceDesoNanos / 1e9).toFixed(2)}</span> DESO<br/>
                                            {localCurrency(additionalData.coinPriceDesoNanos / 1e9, preferences, exchangeRates)}
                                        </div>
                                    </div>
                                </div>
                            ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className="card shadow-sm h-100 text-center action" onClick={() => setActiveTab('creatorcoin')}>
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-bar-chart me-3`}></i>Distribution</h5>
                        </div>
                        <div className="card-body">
                            {additionalData && additionalData.coinPriceDesoNanos !== undefined ? (
                                <div className='row h-100 d-flex align-items-center'>
                                    <div className='col-12 align-items-center text-center'>
                                        <div>
                                            <p className="card-text">
                                                <i className="bi bi-coin me-2"></i>{(additionalData.ccCoinsInCirculationNanos / 1e9).toFixed(2)} coins<br/>
                                                <i className="bi bi-people me-2"></i>{additionalData.creatorCoinBalances.totalCount.toLocaleString()} holders<br/>
                                                <i className="bi bi-pie-chart-fill me-2"></i>{additionalData.hhi !== null ? additionalData.hhi : <Spinner animation="border" />} HHI
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className="card shadow-sm h-100 text-center action" onClick={() => setActiveTab('creatorcoin')}>
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-alarm me-3`}></i>Own Coin Trades</h5>
                        </div>
                        <div className="card-body">
                            {additionalData && additionalData.coinSells !== undefined && additionalData.coinBuys !== undefined ? (
                                <div className='row h-100 d-flex align-items-center'>
                                    <div className='col-6 align-items-center text-center'>
                                        <h6>Buys</h6>
                                        {additionalData.coinBuys.totalCount} buys<br/>
                                        {(additionalData.coinBuys.coins/10e9).toFixed(2)} coin<br/>
                                        {(additionalData.coinBuys.totalValue/10e9).toFixed(2)} DESO<br/>
                                        {localCurrency(additionalData.coinBuys.totalValue/10e9,preferences,exchangeRates)}
                                    </div>
                                    <div className='col-6 align-items-center text-center'>
                                        <h6>Sells</h6>
                                        {additionalData.coinSells.totalCount} sells<br/>
                                        {(additionalData.coinSells.coins/10e9).toFixed(2)} coin<br/>
                                        {(additionalData.coinSells.totalValue/10e9).toFixed(2)} DESO<br/>
                                        {localCurrency(additionalData.coinSells.totalValue/10e9,preferences,exchangeRates)}
                                    </div>
                                </div>
                            ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-underline nav-justified mb-3">
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'coinHolders' ? 'active' : ''}`} onClick={() => handleTabChange('coinHolders')}>
                        {additionalData && additionalData.coinHolders && additionalData.coinHolders.length && (additionalData.coinHolders.length)} Coin Holders
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'coinHolding' ? 'active' : ''}`} onClick={() => handleTabChange('coinHolding')}>
                        Holds {additionalData && additionalData.coinHolding && additionalData.coinHolding.length && (additionalData.coinHolding.length)} Coins
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div className={`tab-pane ${activeTab === 'coinHolders' ? 'active' : ''}`}>
                    <HodlerList profile={profile} hodlers={additionalData.coinHolders} preferences={preferences} exchangeRates={exchangeRates} />
                </div>
                <div className={`tab-pane ${activeTab === 'coinHolding' ? 'active' : ''}`}>
                    <HodlerList profile={profile} hodlers={additionalData.coinHolding} preferences={preferences} exchangeRates={exchangeRates}  />
                </div>
            </div>
        </div>
    );
};

export default ProfileCoin;

export const HodlerList = ({ profile, hodlers, preferences, exchangeRates }) => {
    const [visibleCount, setVisibleCount] = useState(10);
    //console.log("[CoinHolder] Coin: "+profile.Username+" :",profile);
    //console.log("[CoinHolder] preferences, exchangerates: ",preferences,exchangeRates);
    if(!hodlers) { return; }
    const showMore = () => {
        setVisibleCount(prevCount => prevCount + 10); // Increase by 10 each time
    };
    return (
        <div className="">
            <div className="row text-end align-items-center mb-3 fw-bold">
                <div className='col-6'>
                    <div className='row'>
                        <div className="col-3 text-start">#</div>
                        <div className="col-9 ps-0 d-flex flex-nowrap align-items-center text-start">
                            User
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className="col-2"><i className="bi bi-gift-fill"></i></div>
                        <div className="col-4 text-end"><i className="bi bi-coin"></i><span className='d-none d-md-block'> s held</span></div>
                        <div className="col-6 text-end">{preferences.currency}</div>
                    </div>
                </div>
            </div>
            {hodlers.slice(0, visibleCount).map((hodler, index) => {
                const username = hodler.ProfileEntryResponse?.Username || hodler.ProfileEntryResponse?.PublicKeyBase58Check || "Unknown";
                const coins = (hodler.BalanceNanos/1000000000);
                const coinPriceDeso = (profile.CoinPriceDeSoNanos/1000000000);
                const coinValueDeso = (coins * coinPriceDeso);
                //console.log("[CoinHolder] "+username+" :",coins,hodler);
                return (
                    <div key={index} className="row text-end align-items-center mb-3">
                        <div className='col-7'>
                            <div className='row'>
                                <div className="d-none d-md-inline col-md-1 flex-shrink-1 text-start">{index + 1}</div>
                                <div className="col-2 col-md-2 text-start">{hodler.HasPurchased ? <i className="bi bi-coin"></i> : <i className="bi bi-gift-fill"></i>}</div>
                                <div className="col-10 col-md-8 ps-0 d-flex flex-nowrap align-items-center text-truncate text-start">
                                    <Avatar publicKey={hodler.ProfileEntryResponse?.PublicKeyBase58Check} className="me-1" type="avatar"/>
                                    <Avatar publicKey={hodler.ProfileEntryResponse?.PublicKeyBase58Check} className="me-1" type="username"/>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            <div className='row'>
                                <div className="col-5 text-end text-truncate">{coins.toFixed(2)}</div>
                                <div className="col-7 text-end text-truncate">{localCurrency(coinValueDeso, preferences, exchangeRates)}</div>
                            </div>
                        </div>
                    </div>
                );
            })}
            {visibleCount < hodlers.length && (
                <div className="text-center">
                    <button className="btn btn-primary btn-sm" onClick={showMore}><i className='bi bi-chevron-double-down me-2'></i>Show More</button>
                </div>
            )}
        </div>
    );
};

const PROFILE_COIN_SELLS_QUERY = `
    query Transactions($condition: TransactionCondition, $first: Int, $filter: TransactionFilter) {
        transactions(condition: $condition, first: $first, filter: $filter) {
          nodes {
            txnMeta
            txIndexMetadata
          }
          totalCount
        }
      }`;