import { Helmet } from 'react-helmet';
import { configure } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getProfileAPI, ProfileCard, ProfileLight, ProfileHeader, ProfileWrapper } from "../utils/profiles";
import { deso_api, deso_graphql } from "../utils/graphql";
import formatPost, { getPosts, remapCommentTree } from "../utils/posts";
import { useUserPreferences } from "../contexts/UserPreferences";
import ExchangeRatesContext from "../contexts/ExchangeRatesContext";
import { Row, Col } from "react-bootstrap";
import { Loader, updateDocumentTitle } from "../utils/helpers";
import { getProfileFromCache } from '../services/profileCache';
import { OutputPost } from '../utils/layouts_posts';
import { CustomContent } from './creatorTools/customContent';
import { CustomContainer, truncatePreview } from '../utils/customContainers';

export const Post = ( nftView = false ) => {
    const { preferences } = useUserPreferences();
    const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
    const { accessGroups, setAccessGroups } = useContext(ExchangeRatesContext);
    const { postHash, username, slug } = useParams();
    const [post, setPost] = useState(null);
    const [finalPost, setFinalPost] = useState(null);
    const [profile, setProfile] = useState(null);
    const [ metadata, setMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formattedPost, setFormattedPost] = useState(null); // State to hold formatted post
    const [postType, setPostType] = useState("post"); // State to hold the post type
    const [loadingPostHash, setLoadingPostHash] = useState(null);
    const [FeaturedImageURL, setFeaturedImageURL] = useState(null);
    const [nftDetails, setNftDetails] = useState(null);
    const [scrollToPostHashHex, setScrollToPostHashHex] = useState(null);
    const [noMoreScrolling, setNoMoreScrolling] = useState(null);

    useEffect(() => {
        setFormattedPost(null);
        setProfile(null);
        setLoading(true);
        setLoadingPostHash(postHash);
        const fetchPost = async () => {
            try {
                if (postHash) {
                    // Fetch post by postHash
                    const request = {
                        PostHashHex: postHash,
                        FetchParents: true,
                    };
                    if (currentUser) {
                        request.ReaderPublicKeyBase58Check = currentUser.PublicKeyBase58Check;
                    }

                    //console.log("[posts.jsx] preferences:", preferences);
                    const data = await deso_api('get-single-post', request, preferences.nodeURI);
                    if(data) {
                        let finalPost = data.PostFound;
                        if(data.PostFound.ParentStakeID) {
                            // is a reply
                            setProfile(finalPost.ParentPosts[0].ProfileEntryResponse);
                        } else {
                            setProfile(finalPost.ProfileEntryResponse);
                        }
                        setPost(finalPost);
                        setScrollToPostHashHex(finalPost.PostHashHex);
                    } else {

                    }
                } else if (username && slug) {
                    // Fetch post by slug using GraphQL
                    //console.log("[posts.jsx] Blog...",username,slug);
                    const variables = {
                        filter: {
                            and: [
                                {"extraData": {
                                    "contains": { "BlogTitleSlug": slug, }
                                }}
                            ]
                        },
                        first: 1 // Assuming you want only one post
                    };
                    const posts = await getPosts(variables, currentUser, preferences);
                    //console.log("[posts.jsx] blog result:",posts);
                    if (posts && posts.nodes && posts.nodes.length > 0) {
                        //console.log("[posts.jsx] Found matching blog",posts.nodes[0]);
                        setPost(posts.nodes[0]); // Assuming you're taking the first post
                        setPostType("blog");
                    } else {
                        // Handle case where post is not found
                    }
                }
            } catch (error) {
                // Handle error
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
        setNoMoreScrolling(null);
    }, [postHash]);

    useEffect(() => {
        //console.log("[posts.jsx] Final output: ",post);
        if(post) {
            updateDocumentTitle(`${post?.ProfileEntryResponse?.Username} - ${truncatePreview(post?.Body,25)}`);
        }
        if(post?.PostExtraData?.CoverImage && post?.PostExtraData?.CoverImage !== '') {
            //console.log("[posts.jsx] has CoverImage.");
            setFeaturedImageURL(post.PostExtraData.CoverImage);
        }
        if(profile && formattedPost) { setLoading(false); } 
    }, [formattedPost,profile]);

    let postOutput;
    if(post?.PostExtraData?.masterContainer || post?.PostExtraData?.containerType) {
        // Custom Content Models - maybe move to output post instead
        postOutput = (
            <CustomContainer postHash={post.PostHashHex} />
        )
    } else {
        postOutput = (
            <OutputPost postData={post} currentUser={currentUser} level={0} type={null} view={null} accessGroups={accessGroups} preferences={preferences} thread={true} preview={false} alternateUsers={alternateUsers} isExpanded={true} />
        );
    }

    useEffect(() => {
        if (!loading && scrollToPostHashHex && postOutput && profile) {
            scrollToElement();
            console.log("[posts.jsx] ScrollTo - Triggering scroll for", `#post_${scrollToPostHashHex}`);
        }
    }, [loading, scrollToPostHashHex, postOutput, profile]);

    const scrollToElement = () => {
        if (!loading && scrollToPostHashHex && postOutput && !noMoreScrolling) {
            setTimeout(() => {
                const targetElement = document.querySelector(`#post_${scrollToPostHashHex}`);
                if (targetElement) {
                    // Calculate desired position with a bit more buffer
                    const desiredPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 20 * parseFloat(getComputedStyle(document.documentElement).fontSize);
                    console.log("[posts.jsx] ScrollTo - Element found, scrolling to position", desiredPosition);

                    // Scroll directly and force set scroll multiple times to ensure it sticks
                    forceScroll(desiredPosition);
                    setTimeout(() => forceScroll(desiredPosition), 100); // Second scroll to handle any possible layout shifts

                    setNoMoreScrolling(true);
                } else {
                    console.log("[posts.jsx] ScrollTo - Element not found for", `#post_${scrollToPostHashHex}`);
                }
            }, 1000); // Keep the delay
        }
    };

    const forceScroll = (position) => {
        window.scrollTo({
            top: position,
            behavior: 'smooth' // Optional: You could remove this if you need to enforce immediate scrolling
        });
    };
    

    return (
        <>
            {post && profile ? (
                <>
                    <Helmet>
                        {metadata}
                    </Helmet>
                    <ProfileWrapper profile={profile} imageSrc={FeaturedImageURL} output={
                        <div className='p-1 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xxl-4 offset-xxl-4'>
                            {nftView === true && (<div>NFT DETAILS</div>)}
                            {postOutput}
                        </div>
                        } />
                </>
            )  : (
                    <div className='container'>
                        {!loading ? (
                            <div className="row px-3 d-flex flex-column vh-100 justify-content-evenly align-items-around">
                                <div className='mt-5 pt-5'>
                                    <h1><i className="bi bi-cone-striped"></i> Sorry, no {postType} found</h1>
                                    <p>The <em>PostHashHex</em> reference supplied returned no content from the blockchain.</p>
                                    <p className='text-truncate'>Value supplied:<br/>{postHash}</p>
                                </div>
                                <p className='mb-5 pb-5'>Please see the <Link to={`/home`}>home feed</Link>, <Link to={`/discover`}>discover</Link> or <Link to={`/search`}>search</Link> to find more content.</p>
                            </div>
                        ) : (
                            <div className="row px-3 d-flex flex-column vh-100 justify-content-evenly align-items-around">
                                <div className="my-5 py-5">
                                    <Loader/>
                                </div>
                            </div>
                        )}
                    </div>
            )}
        </>
    );
};

export const getNftDetails = async (PostHashHex) => {
    //console.log("[posts.jsx] NFT Details for post:", PostHashHex);

    try {
        const response = await deso_graphql({
            query: `
                query Transactions($filter: TransactionFilter, $orderBy: [TransactionsOrderBy!], $last: Int) {
                    transactions(filter: $filter, orderBy: $orderBy, last: $last) {
                        nodes {
                            txIndexMetadata
                            timestamp
                            transactionType {
                                type
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                filter: {
                    and: [
                        {
                            txnType: {
                                in: [
                                    15, 16, 17, 18, 19, 20, 21
                                ]
                            },
                            txIndexMetadata: {
                                contains: {
                                    NFTPostHashHex: PostHashHex
                                },
                                containsKey: "SerialNumber"
                            },
                        }
                    ]
                },
                orderBy: ["TIMESTAMP_DESC"],
                last: 15
            },
            operationName: "Transactions"
        });

        const data = await response;

        //console.log("[posts.jsx] NFT Details returned", data);

        // Extract relevant information from the response and format the output
        const formattedDetails = data.transactions.nodes.map(node => ({
            txIndexMetadata: node.txIndexMetadata,
            timestamp: node.timestamp,
            transactionType: node.transactionType.name,
        }));

        return formattedDetails;
    } catch (error) {
        //console.error('[posts.jsx] NFT Details Error fetching NFT details:', error.message);
        // You can handle errors here, e.g., return a default value or throw an error
        return null;
    }
};

/*
const generateMetadata = (post, preferences) => {
    const metadata = [];
    //console.log("generateMetadata for ",post);
    const timestampNanos = post.PostFound.TimestampNanos;
    const timestampSeconds = Math.floor(timestampNanos / 1e9); // Convert nanoseconds to seconds
    const isoDateTime = new Date(timestampSeconds * 1000).toISOString();
    
    // Title
    let title;
    let description;

    if(post.PostFound.Title) {
        title = `${post.PostFound.ProfileEntryResponse.Username}: ${post.PostFound.Title}`
    } else { 
        title = `${post.PostFound.ProfileEntryResponse.Username}s post:`; 
    }

    if(post.PostFound.Body) {
        description = `${post.PostFound.Body.substring(0, 27)}...`;
    }

    metadata.push(<meta key="og:title" property="og:title" content={title} />);
    metadata.push(<meta key="og:description" property="og:description" content={description} />);

    // Author
    metadata.push(<meta key="article:author" property="article:author" content={post.PostFound.ProfileEntryResponse.Username} />);

    // Published and Modified Time
    metadata.push(<meta key="article:published_time" property="article:published_time" content={isoDateTime} />);
    metadata.push(<meta key="article:modified_time" property="article:modified_time" content={isoDateTime} />);

    // Type
    metadata.push(<meta key="og:type" property="og:type" content="article" />);

    // URL
    metadata.push(<meta key="og:url" property="og:url" content={window.location.href.toString()} />);

    // Twitter Card
    metadata.push(<meta key="twitter:card" name="twitter:card" content="summary_large_image" />);
    //metadata.push(<meta key="twitter:site" name="twitter:site" content="@YourTwitterHandle" />);
    metadata.push(<meta key="twitter:title" name="twitter:title" content={title} />);
    metadata.push(<meta key="twitter:description" name="twitter:description" content={description} />);

    // Images
    if (post.PostFound.ImageURLs && post.PostFound.ImageURLs.length > 0) {
        post.PostFound.ImageURLs.forEach((imageUrl, index) => {
            if (imageUrl && imageUrl !== '') {
                metadata.push(<meta property="og:image" content={imageUrl} />);
                metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
                metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
                metadata.push(<meta name="twitter:image" content={imageUrl} />);
                metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
            }
        });
    } else {
        // Include a profile banner or avatar instead
        if(post.PostFound && post.PostFound.ProfileEntryResponse && post.PostFound.ProfileEntryResponse.ExtraData && post.PostFound.ProfileEntryResponse.ExtraData.FeaturedImageURL) {
            // FeaturedImageURL
            const imageUrl = post.PostFound.ProfileEntryResponse.ExtraData.FeaturedImageURL;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        } else if(post.PostFound && post.PostFound.ProfileEntryResponse && post.PostFound.ProfileEntryResponse.ExtraData && post.PostFound.ProfileEntryResponse.ExtraData.LargeProfilePicURL) {
            // LargeProfilePicURL
            const imageUrl = post.PostFound.ProfileEntryResponse.ExtraData.LargeProfilePicURL;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        } else {
            // Standard Avatar
            const imageUrl = preferences.nodeURI+`/v0/get-single-profile-picture/${post.PostFound.ProfileEntryResponse.PublicKeyBase58Check}`;
            metadata.push(<meta property="og:image" content={imageUrl} />);
            metadata.push(<meta property="og:image:secure_url" content={imageUrl} />);
            metadata.push(<meta property="og:image:alt" content="Image Alt Text" />);
            metadata.push(<meta name="twitter:image" content={imageUrl} />);
            metadata.push(<meta name="twitter:image:alt" content="Image Alt Text" />);
        }
    }

    // Videos
    if (post.PostFound.VideoURLs && post.PostFound.VideoURLs.length > 0) {
        post.PostFound.VideoURLs.forEach((videoUrl, index) => {
            if (videoUrl && videoUrl !== '') {
                metadata.push(<meta property="og:video" content={videoUrl} />);
                metadata.push(<meta property="og:video:secure_url" content={videoUrl} />);
                metadata.push(<meta name="twitter:player" content={videoUrl} />);
            }
        });
    }

    return metadata;
};
*/